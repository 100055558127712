import { LookupColumn, LookupData } from './lookup.model';
import { UserDataModel } from './user-data-filter.model';
import { AudienceCategoryGroup } from './audience-filter.model';
import { DynamicKeyObjectData } from './Dynamic.model';
import { ProductCatalogueHolder, ProximityProhibitionGroup, NetworkGroup } from './workspace';
import { AssignedTo } from './assigned-to';
import { SalesTeam } from './sales-team';
import { GLOBAL } from '../core/utils/app.constant';
export class InitialConfigModel {
  alternateDesign: number;
  uiControl: UiControl;
  lookupColumn: LookupColumn[];
  lookupData: LookupData[];
  userBundle: any;
  proximityProhibitionGroup: ProximityProhibitionGroup[];
  poiTypes: PoiType[];
  serviceCalls: ServiceCalls;
  audienceCategoryGroup: AudienceCategoryGroup[];
  userData: UserDataModel;
  productCatalogueHolder: ProductCatalogueHolder;
  mediaType: MediaTypeModel[];
  linkedChannels: [number[]];
  tagGroup: DynamicKeyObjectData<TagGroupModel>;
  networkGroup: DynamicKeyObjectData<NetworkGroupModel>;
  audienceTags: DynamicKeyObjectData<string>;
  systemData: SystemData;
  auditType?: AuditType[];
  productCategoryGroup: ProductCategoryMain[];
  dayPartGroup: DayPartGroup;
  productGroup: ProductGroup[];
  splitable?: boolean;
  assignedTo?: AssignedTo[];
  salesTeam?: SalesTeam[];
  bookingStatus: BookingStatus[];
  geoLevels: GeoLevels[];
  campaignTypeGroups: CampaignTypeGroup[];
  readOnlyWorkspace: boolean;

}

export class CampaignType {
  campaignTypeId: number;
  campaignTypeName: string;
  default: boolean;
  campaignTypeCode: string;
}

export class CampaignTypeGroup {
  campaignTypeGroupId: number;
  campaignTypeGroupName: string;
  default: boolean;
  campaignTypeGroupCode: string;
  campaignType: CampaignType[];

}

export class ProductCategoryMain {
  productCategory: ProductCategory[];
  productCategoryGroupCode: string;
  productCategoryGroupId: number;
  productCategoryGroupName: string;
}

export class ProductCategory {
  productArea: ProductArea;
  productCategoryCode: string;
  productCategoryGroup: ProductCategoryGroup;
  productCategoryId: number;
  productCategoryName: string;
}
export class ProductArea {
  idProductArea: number;
  productAreaCode: string;
  productAreaName: string;
}
export class ProductCategoryGroup {
  idProductCategoryGroup: number;
  productCategoryClass: ProductCategoryClass;
  productCategoryGroupCode: string;
  productCategoryGroupName: string;
}

export class ProductCategoryClass {
  idProductCategoryClass: 1;
  productCategoryClassCode: string;
  productCategoryClassName: string;
  productCategoryFamily: ProductCategoryFamily;
}
export class ProductCategoryFamily {
  idProductCategoryFamily: number;
  productCategoryFamilyCode: string;
  productCategoryFamilyName: string;
}

export class SupportedLanguage {
  locale: string;
  language: string;
}

export class UiControl {
  defaultThirdPartyAgencyCommission = 0;
  defaultThirdPartySpecialistCommission = 0;
  defaultSSPUIThirdPartySpecialistCommission = 0;
  defaultSSPUIThirdPartyAgencyCommission = 0;
  enableProductId: boolean;
  hiddenElements: string[] = null;
  defaultInChargeDay: number = null;
  defaultInChargeLength: number = null;
  processingBrics: number[] = null;
  showSingleColumnSummary: boolean;
  alwaysDisplayColumnSummary: boolean;
  multiAudienceBrics: boolean;
  invoiceClientStatusEnabled: boolean;
  mapsAPIClientId: string = null;
  mapsAPIChannel: string = null;
  hidePrice: boolean;
  enableSmartUnlock: boolean;
  baiduMapEnabled: boolean;
  enableShowFrameOrientations: boolean;
  baiduMapApiKey: string = null;
  apiSearchEnabled: boolean;
  requestSearchEnabled: boolean;
  sotSlotsEnabled: boolean;
  sotFrequencyEnabled: boolean;
  sotFrequencyMin: number = null;
  sotFrequencyMax: number = null;
  budgetSnowFallEnabled: boolean;
  availabilityEnabled: boolean;
  availabilityResultEnabled: boolean;
  // tslint:disable-next-line:variable-name
  CCPHideFinanceEnabled: boolean;
  // tslint:disable-next-line:variable-name
  CCPHideImpressionEnabled: boolean;
  proposalCampaign: boolean;
  bricsWithSelectionIDs: object = null;
  proximityProhibitionGroup: ProximityProhibitionGroup[] = null;
  poiTypes: PoiType[] = null;
  optionalBrics: (string | number)[] = null;
  volumeRangeValidationEnabled: boolean;
  searchTableColumns: string[];
  showUpdateButton: boolean;
  hardAllocationEnabled?: boolean;
  swapEnabled?: boolean;
  toleranceEnabled?: boolean; // TODO: this will we required property once that backend deploy the code, need to check with Nishit
  defaultAllocateAllFrames?: boolean; // TODO: this will we required property once that backend deploy the code, need to check with Shreni
  displayWeekNumber: boolean;
  secondaryAudienceGroups: number[];
  maxSegmentSelection: number;
  overlappingRange: boolean; // If true Overlapping dates can be selected
  reshuffleEnabled: boolean; // reshuffling is enable or not
  competitionEnabled: boolean;
  showOffPostWarning: boolean; // SM-4408
  checkToleranceLimit: boolean;
  externalUser: boolean;
  containsPaper: boolean;
  geoLocationTree: boolean;
  showRevertOptionToProposal: boolean;
  showRequestOptionButton: boolean;
  showProposalButton: boolean;
  showRequestBookButton: boolean;
  showBookButton: boolean;
  showPerformanceButton: boolean;
  showOvershowToggle: boolean;
  autoCalculateFloorAndCeiling: boolean;
  floorCeilingPrecision: number;
  sotSwing: number;
  visualPlannerPastWeeks: number;
  defaultTolerance: number;
  defaultOptionStack: boolean;
  showInvalidProducts: boolean;
  enforcePCMRulesForPattern: boolean;
  enforcePatternStabilityforPCM: boolean;
  bgvGreaterThanZero: boolean;
  enableROCancelForBookingStatus: number[];
  enableROCancelReasonList: boolean;
  enableROChangeForBookingStatus: number[];
  enableROChangeReasonList: boolean;
  triggerROChangeType: number;
  historyROC: boolean;
  allowDefaultMediaAgency: boolean;
  overrideProposalExpiryDate: boolean;
  proposalGlobalExpiryPeriodInDays: number;
  proximityPOITree?: boolean;
  perSwapCampaignsLimit = 8;
  inchargeContinuousFromLastYear: boolean;
  pricingTagMinimumEnabled?: boolean;
  hideAllocationAdjustment?: boolean;
  showCancelButton?: boolean;
  showOptionButton?: boolean;
  showSaveButton?: boolean;
  allocationAudienceDiscrepancy: number;
  reshuffleEngine?: string;
  greenToleranceDefault: number;
  orangeToleranceDefault: number;
  toleranceMin: number;
  indoorMapBaseUrl: string;
  hiddenColumns: string[] = null;
  mediaLengthRequired?: number[];
  tradingEnabled?: boolean;
  enableCustomPeriodSwapForPaper: boolean;
  supportedLanguages: object;
  svgMarkerEnabled?: boolean;
  restrictIndoorFramCartSelections?: boolean;
  hideShowGMapBackgroundEnabled?: boolean;
  pcmPriceConfigurationEnabled?: boolean;
  pcmPricingFactors?: string[];
  guidingIndoorMap?: boolean;
  disableZoom?: boolean;
  draggableFurnitureSelectionBox?: boolean;
  enableFOC = false;
  disabledElements?: string[];
  allowHideFinancialCCPEdit?: boolean;
  allowHideImpressionCCPEdit?: boolean;
  showActionButtons?: boolean;
  showDiscountGrid?: boolean;
  pendoEnabled = false;
  networkBricAvailabilityControls?: boolean;
  defaultNetworkAvailabilityFilter?: boolean;
  defaultNetworkUnmatchedFilter?: boolean;
  enableNewCommercialConfiguration?: boolean;
  showFloatingDateReshufflingDetails?: boolean; // Used to hide/unhide floating date columns in reshuffling details modal,
  executeButtonGmVp?: boolean;
  searchDefaultExpandSmartSearch?: boolean; // Used at search page, if true then smart search will be expanded by default, SM-8076
  resultDefaultExpandMap?: boolean; // property to default expand map or concertina at Result tab
  defaultMarkerLabelVisibility?: boolean;
  allocateImpressionsToFTG?: boolean;
  maxFinalAttributeIterations?: number;
  concertinaInAlphaNumericOrder?: boolean;
  downloadConcertinaInBlackWhite?: boolean;
  locationInAlphabeticalOrder?: boolean;
  defaultAllocationEngine: string;
  allowReductionBrickSpan: boolean;
  allowAllocationEngineChange: boolean;
  fixedPackagePriceEnabled: boolean; // SM-8087
  expandPatternBrickOverMultipleColumns: boolean; // SM-6474
  invoiceLockFeature?: boolean;
  poiEnhancedTree: boolean; // SM-8090
  hourlyPatternFunctionEnabled: boolean;
  allowCampaignClone?: boolean;
  inchargeExceptionYears = [];
  campaignCPTScale: number;
  objectiveFrameIlluminationEnabled?: boolean;
  hideObjectivePriority?: boolean;
  hideObjectiveWeight?: boolean;
  objectiveSotFloorCeilingEnabled?: boolean;
  objectiveAllocateAllFramesEnabled?: boolean;
  reshuffleLoadingMessages = [];
  openRateCardsEnabled?: boolean;
  showDayofWeekRangeBric?: boolean;
  showWeekNumberForPattern?: boolean;
  externalProposalAppUrl?: string;
  allowFileUploadForLocationBric = false; // SM-9277
  hideShowAdvertiserIdEnabled?: boolean;
  hideDisabledProductsInPcm?: boolean;
  collapseDiscountGridbyDefault?: boolean;
  defaultSmartSearchResultsPerPage?: number;
  bgvEditableUsingToken?: boolean;
  enableUserDefinedCPMPricingCalculation?: boolean;
  ignoreOOCFrames = false; // SM-8217
  geoMarkerLabelDefaultTextSize?: number;
  geoMarkerDefaultIconSize?: number;
  allowSpotAllocation?: boolean; //SM-10361
  applyLimitOnSearch?: boolean; // SM-9936
  shortenFurnitureCode?: boolean; // SM-10108
  reshuffleScopingForObjectiveWorkspaceEnabled?: boolean;
  reshuffleScopingEnabled?: boolean; // SM-10356
  showCCP3LinkButton?: boolean; // SM-10258
  defaultSortByCampaignReference?: boolean; // SM-10508
  enableDateFilterSelection?: boolean; // SM-10435
  showDefaultFrameOrientation?: boolean // SM-11052,SM-10299
  dateFormat?: string; // SM-10435
  showTouchedPOIOnly?: boolean;
  allowEditToTouchedPOIs?: boolean;
  enableTouchedPOIFeature?: boolean;
  showNewBookingLineWarningOnReshuffle?: boolean; //SM-9864
  showNewDiscountLineWarningOnReshuffle?: boolean; //SM-9864
  enableIconSwitcher?: boolean; //SM-11073
  enableNewFolioFurnitureAPI?: boolean;
  enableFormatBrickFilter?: boolean; //SM-11336
  isBrandMandatory: boolean;
  isNetworkAsaFilterEnabled?: boolean; //SM-11641
  showTargetImpression?: boolean; //SM-11662
  defaultPOITypeId?: number = undefined; // SM-11538
  showCommercialDetailsOnWorkspace?: boolean; //SM-11901
  isNewDiscountGridEnabled?: boolean // SM-12053
  dataDogApplicationId: string = null;
  dataDogClientToken: string = null;
}

export class PoiType {
  poiTypeId: number = null;
  poiTypeName: string = null;
  poiTypeCode?: string = null;
  poiAttributes?: PoiAttribute[] = [];
}

export class PoiAttribute {
  poiAttributeId: number = null;
  poiAttributeName: string = null;
  inUse: boolean;
}

export class ServiceCalls {
  LOOKUP_ROUTEID_URL: string = null;
  PROXIMITY_POSTCODE_URL: string = null;
  FILE_UPLOAD_PROXIMITY_URL: string = null;
  KEEP_ALIVE_URL: string = null;
  PROXIMITY_POINT_OF_INTEREST_URL: string = null;
  LOOKUP_RESPONSE_URL: string = null;
  MAP_DATA_URL: string = null;
  CAMPAIGN_URL: string = null;
  FILE_UPLOAD_URL: string = null;
  CAMPAIGNS_LS_RESHUFFLE: string = null;
}

export class MediaTypeModel {
  mediaTypeId: number;
  mediaTypeName: string;
  defaultAllocationEngine?: string = GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO;
}

/**
 * @description Used for Pricing tag id:19
 */
export class NetworkModel {
  networkId: number;
  networkName: string;
  networkDefaultQuantity: number;
  inUse: boolean;
  networkMinQuantity: number;
}

/**
 * @description Used for Pricing tag id:19
 */
export class NetworkGroupModel {
  networkGroupId: number;
  networkGroupName: string;
  inUse: boolean;
  network: NetworkModel[];
}

export class TagGroupModel {
  tagGroupId: number;
  tagGroupName: string;
  inUse: boolean;
  tag: TagModel[];
}

export class TagModel {
  tagId: number;
  tagName: string;
  inUse: boolean;
  exclude?: boolean;
  isDisabled?: boolean;
  relative?: boolean;
}

export class SelectionItemModel {
  disabled?: boolean;
  id?: number;
  lookupId?: number;
  inUse?: boolean;
  isVisible?: boolean;
  name?: string;
  selected?: boolean;
  relative?: boolean;
}

export class SystemData {
  environmentId: number;
  serverId: number;
  version: string;
  systemTitle: string;
  systemType: string;
  systemName: string;
  locale: string;
  bookingSystemEnabled: boolean;
  salesOrderEnabled: boolean;
  pipeEnabled: boolean;
  maxInactiveInterval: number;
  currencySymbol: string;
}

export class AuditType {
  auditTypeCode: string;
  auditTypeId: number;
  auditTypeName: string;
  auditReason: AuditTypeReason[];
}

export class AuditTypeReason {
  auditReasonCode: string;
  auditReasonId: number;
  auditReasonName: string;
  specifyOther: boolean;
}

export class ProductGroup {
  productGroupId: number;
  productGroupName: string;
  inUse: boolean;
  product: Product[];
}

export class Product {
  productId: number;
  productName: string;
  inUse: boolean;
  product: Product[];
  index: number;
  relative?: boolean;
}

export class DayPartGroup {
  dayPartGroupCode: string;
  dayPartGroupId: number;
  dayPartGroupName: string;
  dayPartGroupSpans: object;
  frameSpans: number[][];
  dayPartGroupDetail: DynamicKeyObjectData<DayPartGroupDetail>;
}

export class DayPartGroupDetail {
  dayPartGroupSpans: DynamicKeyObjectData<DayPartSpan>;
  dayPartId: number;
  displayName: string;
  endOffset: number;
  startOffset: number;
}

export class DayPartSpan {
  endLabel: string;
  endOffset: number;
  spanId: number;
  startLabel: string;
  startOffset: number;
  dayPartId: number;
}

export class Hod {
  bricsData: DynamicKeyObjectData<object>;
  channelId: number;
  hodId: number;
  hodName: string;
  inUse: boolean;
}

export class Daypart {
  dayPartId: number;
  endOffset: number;
  startOffset: number;
}

export class BookingStatus {
  booked?: boolean;
  bookingStatusCode?: string;
  bookingStatusId: number;
  bookingStatusName: string;
  inUI?: boolean;
  inUse: boolean;
  updateBookingSystem?: boolean;
}

export class GeoLevels {
  id: number;
  name: string;
  inUse: boolean;
  child: GeoLevels[];
}

export class POIHolder {
  id: string;
  poiId: number;
  poiName: string;
  poi: POIHolder[];
  relative;
  poiTypeId: number;
  poiTypeName: string;
}

export class GroupData {
  groupData: ProductGroup[] | NetworkGroup[] | DynamicKeyObjectData<TagGroupModel>;
}
