import {
  Component, Type, ViewChild,
  ViewEncapsulation, ComponentFactoryResolver, OnInit, OnDestroy,
  Renderer2,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from './core/services/data-share.service';
import { SyncWsBricksService } from './core/services/sync-ws-bricks.service';
import { BricHostDirective } from './core/directives/bric-host.directive';
import { CartService } from './geo-map/cart.service';
import { CampaignService } from './core/services/campaign.service';
import { StateService } from './core/services/state.service';
import { LogHelperService } from './core/services/log-helper.service';
import { RequestJsonService } from './geo-map/request-json.service';
import 'rxjs/add/operator/map';
import { Subscription } from 'rxjs';
import { AppNameEnum } from './core/enum';
import { SystemFlags, ResponseModel, SaveFrameResponseModel, FrameModel, Filter } from './models';
import * as _ from 'lodash';
import { GLOBAL } from './core/utils/app.constant';

@Component({
  selector: 'app-geo-planner',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './app-secondary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Defines brick host directive view instance.
   */
  @ViewChild(BricHostDirective) bricHost: BricHostDirective;

  /**
   * Defines map element instance.
   */
  @ViewChild('mapDiv') mapDiv: ElementRef;

  /**
   * @description initial cart data on geomapper load
   * needed to compare on route change to detect change in data or not
   * @type {FrameModel[]}
   * @memberof AppComponent
   */
  initialCartData: FrameModel[] = [];

  /**
   * Flag which defines filter area visible or not.
   */
  public isFilterAreaVisible = true;
  /**
   * Flag which defines filter bar expanded or not.
   */
  public isFilterBarExpanded = false;
  /**
   * It defines initial configuration data.
   */
  public initialConfig: any = { userBundle: [] };
  /**
   * Subscriber for map data.
   */
  public mapDataSubscriber: Subscription;
  /**
   * Defines left alignment for filter window.
   */
  public secondSliderLeft = '-330px';
  /**
   * Flag to define side bar is large or not.
   */
  public isLargeDetailSidebar = false;
  /**
   * Time to open for side bar.
   */
  sliderTimer: any;

  /**
   * @description flag to check navigation allowed or not, used in can deactivate GM service in router
   * @type {boolean}
   * @memberof AppComponent
   */
  public allowNavigation = false;

   /**
   * Defines top alignment for filter window.
   */
   public filterPaddingTop = '80px';
   /**

  /**
   * Constructor
   * @param dataShareService Service to share data between compoenents.
   * @param componentFactoryResolver Factory for component.
   * @param CartService Service for geo map data.
   */

  constructor(
    private dataShareService: DataShareService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cartService: CartService,
    private campaignService: CampaignService,
    private stateService: StateService,
    private logHelperService: LogHelperService,
    private requestJsonService: RequestJsonService,
    private router: Router,
    private syncWsBricksService: SyncWsBricksService,
    private renderer: Renderer2
  ) {
    this.campaignService.restoreGeoMapperBackupData();
  }

  /**
   * @description callback function - called when user navigate to other screen
   * @author Alkesh Shah
   * @param {*} url
   * @returns
   * @memberof AppComponent
   */
  beforeNavigate(url) {
    return new Promise((resolve) => {
      this.campaignService.takeGeoMapperBackupData();
      const originalCartData = JSON.stringify(this.initialCartData);
      const latestCartData = JSON.stringify(this.cartService.cartData);

      this.syncWsBricksService.getBricks().subscribe((res) => {
        if (res.status === 'OK') {
          // when geomapper cart is empty and user moves to other page
          // or when user delete all frames from cart and moves to other page
          // backend will not send data.
          // need to reset workspace
          if (!res.data) {
            res.data = {
              bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
              bricsData: [],
              campaignParameters: this.stateService.getWorkspaceObject('campaignParameters'),
              campaignSummary: [],
              columnConfig: {},
              columnHelper: {},
              columnLineNumber: {},
              columnSummary: {},
              readOnly: false,
              readOnlyWorkspace: false,
              splitable: false
            };
          }
          this.dataShareService.finalPrice = res.data.campaignSummary.map(summary => summary.finalPrice);
          const filterObj: Filter = this.stateService.getWorkspaceFilterObj();
          filterObj.updateLookupData(res.data.bricsData, res);
          if (originalCartData !== latestCartData) {
            SystemFlags.getBasketDataForVP = true;
            SystemFlags.isCampaignSummaryChanged = true;
            // SM-4348 - maintain reshuffle column if no change done in Campaign from GM
            filterObj.reshuffle.reshufflingColumns = [];
            filterObj.toleranceLimit.cellAddress = [];
            this.stateService.setWorkspaceObject('filter', filterObj);
          } else {
            SystemFlags.isCampaignSummaryChanged = false;
          }
          this.syncWsBricksService.processCampaignLoad(res);
        } else {
          this.logHelperService.logError(res.message);
        }

        this.allowNavigation = true;
        this.router.navigate([url]);
        resolve(url);
      });

    });
  }

  /**
   * To load filter component data.
   * @param param Object which contains data for filter to be loaded
   */
  loadBrickDetailComponent(param: { component: Type<any>, resolveObject: any }) {
    clearTimeout(this.sliderTimer);

    this.isLargeDetailSidebar = false;
    if (param.resolveObject.isLargeSidebar) {
      this.isLargeDetailSidebar = true;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(param.component);

    if (this.bricHost) {
      const viewContainerRef = this.bricHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      (componentRef.instance).resolveObject = param.resolveObject;
      (componentRef.instance).closeSidebar.subscribe(this.hideBrickDetSideBar);
    }

    this.openFilterDetailSlider();
  }

  /**
   * To hide brick detail side bar.
   */
  hideBrickDetSideBar = () => {
    this.closeFilterDetailSlider();
    this.sliderTimer = setTimeout(() => {
      if (this.bricHost) {
        const viewContainerRef = this.bricHost.viewContainerRef;
        viewContainerRef.clear();
      }
    }, 2000);
  }

  /**
   * To toggle filter area.
   */
  toggleFilterArea() {
    if (this.isFilterAreaVisible) {
      if (this.secondSliderLeft === '250px') {
        this.hideBrickDetSideBar();
      }
      if (!document.body.classList.contains('filter-open')) {
        document.body.className = `${document.body.className} filter-open`;
        this.renderer.setStyle(this.mapDiv.nativeElement, 'padding-left', '250px');
      } else {
        document.body.className = document.body.className.replace('filter-open', '');
        this.renderer.setStyle(this.mapDiv.nativeElement, 'padding-left', '0px');
      }
    } else {
      document.body.className = document.body.className.replace('filter-open', '');
      this.renderer.setStyle(this.mapDiv.nativeElement, 'padding-left', '0px');
    }
    this.isFilterAreaVisible = !this.isFilterAreaVisible;
  }

  /**
   * Toggle filter bar.
   * @param value Boolean flag for expanded or not.
   */
  onFilterBarToggle(value) {
    this.isFilterBarExpanded = value;
  }

  /**
   * To open filter detail sidebar.
   */
  openFilterDetailSlider() {
    this.secondSliderLeft = '250px';
  }

  /**
   * To close filter detail sidebar.
   */
  closeFilterDetailSlider() {
    this.isLargeDetailSidebar = false;
    this.secondSliderLeft = '-330px';
  }

  /**
   * Function that loads initially.
   */
  ngOnInit() {
    this.dataShareService.appName = AppNameEnum.geomapper;
    this.initialConfig = this.dataShareService.getInitialConfig();

    this.mapDataSubscriber = this.cartService.cartData$
      .subscribe(this.cartDataSubscription);

    // if new campaign is loaded clear all gm cache data and reload cart data
    const campaignLoadObj = this.stateService.getGeoMapperObject('campaignLoad');
    if (SystemFlags.getBasketDataForGP || campaignLoadObj.isLoadData) {
      this.dataShareService.shouldLoaderStoppedForGeoMapper = false;
      this.cartService.getBasket().then((res: ResponseModel<SaveFrameResponseModel>) => {
        if (res.status === 'OK') {
          const result = res.data;
          if (result) {
            this.cartService.setIndoorMaps(result.indoorMaps);
            let familyMapIcon = result.familyMapIcon ? result.familyMapIcon : [];
            this.dataShareService.setFamilyMapIcon(familyMapIcon);
            let frameSizeFamilyIcon = result.frameSizeFamilyMapIcons ? result.frameSizeFamilyMapIcons : [];
            this.dataShareService.setFrameSizeFamilyIcon(frameSizeFamilyIcon);
            let frameOrientations = result.frameOrientations ? result.frameOrientations : [];
            this.dataShareService.setFrameOrientations(frameOrientations).then(res => {
            if(res) {
              this.cartService.setCartSummary(result.campaignSummary[0]);
              this.cartService.rebuildCartData(result.basketData);
              this.cartService._Frames = [];
              this.cartService._Frames = Object.assign(this.cartService.cartData);
              this.campaignService.loadCartOnMap();
              this.campaignService.clearBackupFilters();
              this.initialCartData = _.cloneDeep(this.cartService.cartData);
            }
            }).catch(err => console.log(err));
          }
        } else {
          this.dataShareService.shouldLoaderStoppedForGeoMapper = true;
          this.logHelperService.logError(res.message);
        }
      });
    }

    // if new need to reset geomapper
    if (campaignLoadObj.isResetCampaign) {
      this.cartService.setCartSummary({});
      this.cartService.rebuildCartData([]);
      this.campaignService.clearFilters();
      this.campaignService.clearBackupFilters();
    }

    // SM-1292 - if new need to refresh map data, if user comes from result/commercial page
    // as requested by andrius
    const gmCommWithOtherPage = this.stateService.getGeoMapperObject('commWithOtherPage');
    if (SystemFlags.getBasketDataForGP) {
      gmCommWithOtherPage.refreshFilter = false;
    }
    if (gmCommWithOtherPage.refreshFilter && this.requestJsonService.requestJsonData.length > 0) {
      this.requestJsonService.requestJsonDataChange();
    }

    gmCommWithOtherPage.refreshFilter = false;
    this.stateService.setGeoMapperObject('commWithOtherPage', gmCommWithOtherPage);
    this.stateService.setGeoMapperObject('campaignLoad', {
      isLoadData: false,
      isResetCampaign: false
    });

    this.initialCartData = _.cloneDeep(this.cartService.cartData);
    // add the listener for full screen change event of google map
    document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));

  }

  /**
   * Full screen change event of google map.
   * Add style for google map & filter sidebar based on the full screen property.
   */
  onFullscreenChange() {
    if (document.fullscreenElement) {
      this.filterPaddingTop = '0px';
      this.renderer.setStyle(this.mapDiv.nativeElement, 'padding-left', '0px');
      document.body.classList.add("full-screnn-body-p");
      document.body.classList.remove('filter-open');
    } else {
      document.body.classList.remove("full-screnn-body-p");
      this.filterPaddingTop = '80px';
      document.body.classList.add('filter-open');
      this.renderer.setStyle(this.mapDiv.nativeElement, 'padding-left', '250px');
    }
  }
  /**
   * Subscription function for map data.
   * @param mapData Data for the map.
   */
  cartDataSubscription = (data) => {
    this.dataShareService.activateResultTab((data.length > 0));
    this.dataShareService.activateCommercialTab((data.length > 0))
  }

  /**
   * To redirect to Result page.
   */
  redirectToResultPage() {
    this.router.navigate(['/result']);
  }

  /**
   * To destroy data for the component.
   */
  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.mapDataSubscriber.unsubscribe();
  }
}
