import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgSelectCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgSelectCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent,
  AgSelectFloatingFilterComponent,
  DataFormatterService,
  AgTextCellRendererComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import * as toasterConfig from '../../../core/toasterConfig';
import { UiControl } from '../../../models';
import { Subscription } from 'rxjs';
import { NegotiationTypes } from '../../../core/enum';
import { CommercialService } from '../../../core/services';
import { CampaignDetails, Discount } from '../../commercial.model';
import { DatePipe } from '@angular/common';
import { LocaleData } from '../../../core/utils/LocaleData';

@Component({
  selector: 'app-discount-grid-brazil-hive',
  templateUrl: './discount-grid-brazil-hive.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-brazil-hive.component.css']
})
export class DiscountGridBrazilHiveComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  @Input() campaignDetails: any = {};
  @Input() brandData = [];
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  totalFrames = 0;
  totalGrossPrice = 0;

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  groupingField: string = null;
  notifyGridSubscription: Subscription;

  precisionOnPrice = 2;
  precisionOnPercentage = 2;

  overrideDiscountOptions = [];
  subs: Subscription[] = [];
  floatingDealStatusCategoryId = 7;

  negotiationTypes = [];
  indexedNegotiationTypes = [];
  public precisionOnPercentageDisplay = 2;
  constructor(
    private dataShareService: DataShareService,
    private toaster: LogHelperService,
    private dataFormatterService: DataFormatterService,
    private commercialService: CommercialService,
    private datePipe: DatePipe
  ) {
    this.subs.push(this.commercialService.discounts$.subscribe((res: Discount[]) => {
      this.rowData = [];      
      this.reset();
      this.footerRowData[0]["applyIndex"] = 'false';
      this.campaignDetails.discounts = _.clone(res);
      this.prepareRowData();
    }));
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.overrideDiscountOptions = [
      {
        value: true,
        label: this.userBundle['discount.es.yes'] || 'Yes',
      },
      {
        value: false,
        label: this.userBundle['discount.es.no'] || 'No',
      }
    ];
    this.setArrayLists();
    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();
    this.reset();
    
    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible,
      onCellClicked: (params) => {
        if (params.rowPinned === 'bottom' && params.rowIndex === 0 && params.column.isCellEditable(params.node)) {
          const par: any = {
            column: params.column,
            rowIndex: params.rowIndex,
            rowPinned: params.rowPinned
          };
          params.api['rowRenderer'].startEditingCell(par);
        }
      }
    } as GridOptions;
    this.onSelectChange(this.rowData, this.rowData[0].applyIndex)
  }

  setNegotiationType() {
    this.negotiationTypes = this.campaignDetails.negotiationTypes;
    this.negotiationTypes.unshift({ negotiationTypeId: -1, negotiationTypeName: this.userBundle['common.lookup.defaultOption'] });
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    AgCustomRowUtil.setParentRowSelectedProperty(this.rowData, this.groupingField);
    // Refresh Grid Header checkbox component
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  setArrayLists() {
    this.setNegotiationType();
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return (rowNode.data.gridSummary ? true : false);
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      if (event.column.colId === 'networkPrice') {
        event.data.networkPrice = LocaleData.replaceLocalDecimalCharWithDot(event.data.networkPrice);
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && $event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target)) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    const tempRowData = [];

    for (const curRow of this.campaignDetails.discounts) {
      curRow.isFilterPass = true;
      curRow.isParent = false;
      curRow.isVisible = true;
      curRow.isSelected = false;
      curRow.negotiationTypeId = curRow.negotiationTypeId ? curRow.negotiationTypeId : -1;
      curRow.originalNetworkIndexValue = curRow.networkIndexValue;
      curRow.networkIndexValue = curRow.applyIndex ? curRow.originalNetworkIndexValue : 100;
      tempRowData.push(curRow);
    }
    this.rowData = _.clone(tempRowData);
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 1 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
    this.footerRowData[0].negotiationTypeId = -1;
  }

  getGridSummary() {
    this.calculateGridSummaryValues();
    return `<span>${this.userBundle['discount.common.totalFrames']}
    : ${this.getChildRows(null, false).length} &#45; ${this.userBundle['discount.common.selectedFrames']}
      : ${this.getSelectedChildRows().length} (<b>${this.userBundle['discount.es.totalFrames']}
      </b>: ${this.totalFrames}, <b>${this.userBundle['discount.br.totalAutomationPrice'] || "Total Automation Price"}
      </b>: ${this.dataFormatterService.currency(this.totalGrossPrice)})</span></div>`;
  }

  prepareColDef() {
    let applyIndexKeyValue = [{id:true,value:'Yes' },{id:false,value:'No'}];
    this.columnDefs = [   
      {
        headerName: '', width: 30, editable: false, pinned: 'left', filter: false, colId: 'selectChk',
        cellRendererFramework: AgCheckboxComponent,
        pinnedRowCellRenderer: this.blankCellRenderer,
        headerComponentFramework: AgCheckboxComponent,
        cellClass: (params): string[] => {
          const retArr: string[] = [];
          if (params.data.isSelected) {
            retArr.push('selectedRow');
          }
          if (params.node.rowPinned === 'bottom' && params.rowIndex === 1) {
            retArr.push('borderBottom');
          }
          return retArr;
        }
      },
      {
        headerName: this.userBundle['discount.brazil.bookingReference'], field: 'bookingReference', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.invoiceTypeName'], field: 'invoiceTypeName', width: 130, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.channelName'], field: 'channelName', width: 180, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.productcatalogue'], field: 'productCatalogueName', width: 180, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      }, 
      {
        headerName: this.userBundle['discount.brazil.networkName'], field: 'networkName', width: 180, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.networkNamePinnedRowCellRenderer,
      },     
      {
        headerName: this.userBundle['discount.brazil.weekNumber'], field: 'weekNumberOfYear', width: 100, editable: false, pinned: 'left',
        pinnedRowCellRenderer: this.blankCellRenderer
      },      
      {
        headerName: this.userBundle['discount.brazil.weekDays'], field: 'weekDays', width: 180, editable: false, pinned: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.dates'], field: 'week', width: 150, editable: false, pinned: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.numberFrames'], field: 'numberFrames', width: 110, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        cellRendererParams: {
          onlyNumber: true,
          cellEditable: () => {
            return false;
          }
        },
        pinnedRowCellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return false;
          },
          calcCellValue: (rows) => {
            let avgNoOfFrames = 0;
            rows.forEach((row) => {
              avgNoOfFrames += row.numberFrames;
            });
            return avgNoOfFrames;
          },
        },
      },
      {
        headerName: this.userBundle['discount.brazil.negotiationType'], field: 'negotiationTypeId',
        width: 150, editable: true,
        cellRendererFramework: AgSelectCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => this.negotiationTypes,
          onSelectChange: (data) => {
            this.onSelectChange([data.data], data.data.applyIndex);
            this.gridOptions.api.redrawRows();
          }
        },
        pinnedRowCellRendererFramework: AgSelectCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => this.negotiationTypes,
          onSelectChange: (data) => {
            this.onSelectChange([data.data], data.data.applyIndex);
            this.gridOptions.api.redrawRows();
          }
        },
        cellEditorFramework: AgSelectCellEditorComponent,
        cellEditorParams: {
          cellEditable: () => {
            return true;
          },
          valKey: 'negotiationTypeId',
          textKey: 'negotiationTypeName',
          dataArr: () => this.negotiationTypes,
          onSelectChange: (data) => {
            this.onSelectChange([data.data], data.data.applyIndex);
            this.gridOptions.api.redrawRows();
          }
        },
      },
      { // Need to check the logic for automation price 
        headerName: this.userBundle['discount.brazil.automationPrice'], field: 'grossPrice', width: 150, editable: false,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false,
          prefixText: (params) => {
            if (params.grossPrice === 0 || (params.grossPrice && params.grossPrice !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false
        }
      },
      {
        headerName: this.userBundle['discount.brazil.applyIndex'], field: 'applyIndex',
        width: 150, editable: false,
        cellRendererFramework: AgSelectCellEditorComponent,
        cellRendererParams: {
          valKey: 'id',
          textKey: 'value',          
          dataArr: () => applyIndexKeyValue,               
          cellDisable: (params) => this.cellDisable(parseInt(params.data.negotiationTypeId)),
          onSelectChange:(data) => {            
            this.onSelectChange([data.data],data.data.applyIndex);
            this.gridOptions.api.redrawRows();
          }
        }        
      },
      {
        headerName: this.userBundle['discount.brazil.networkVolumeIndex'], field: 'networkIndexValue', width: 150, editable: false,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPercentageDisplay,
          cellEditable: false
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      },
      {
        headerName: this.userBundle['discount.brazil.finalPrice'], field: 'finalPrice', width: 150, editable: false,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false,
          prefixText: (params) => {
            if (params.grossPrice === 0 || (params.grossPrice && params.grossPrice !== '')) {
              return '';
            } else {
              return this.userBundle['discount.common.total'];
            }
          },
          gridSumRow: 1
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          allowReplaceDecimalSeparator: true,
          decimalPoints: this.precisionOnPrice,
          cellEditable: false
        }
      }
    ];

    this.numericFields = [
      'numberFrames'
    ];

    this.dateRangeFields = [

    ];
    this.selectFields = ['negotiationTypeId'];

    const decimalFieldsForFilter = [
      'grossPrice'
    ];
    for (let i = 1; i < this.columnDefs.length; i++) {

      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };

      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }

        if (params.node.rowPinned === 'bottom' && (i > 4 || params.rowIndex === 1)) {
          retArr.push('borderBottom');
        }

        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        if (this.columnDefs[i].field === 'negotiationTypeId' &&
          (params.node.rowPinned !== 'bottom' && params.data.negotiationTypeId === -1 || params.data.negotiationTypeId === '-1')) {
          retArr.push('danger');
        }
        return retArr;
      };
    }

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
  }

  blankCellRenderer() {
    return '';
  }

  networkNamePinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // Reset Icon
      const eImg: HTMLImageElement = document.createElement('img');
      eImg.src = 'images/general/reset.png';
      eImg.title = 'Reset';
      eImg.onclick = this.reset;
      eDiv.appendChild(eImg);
    } else if (params.rowIndex === 1) {
      eDiv.innerHTML = `<span class='cellRightSpan fontBold'>${this.userBundle['discount.common.totalBar']}</span>`;
    }
    return eDiv;
  }

  updateRowOnOverrideDiscount(selectedRow: Discount) {
    if (selectedRow.overrideDiscounts && String(selectedRow.overrideDiscounts) === 'true') {
      selectedRow.discount = 0;
      selectedRow.mediaDiscount1 = 0;
      selectedRow.mediaDiscount2 = 0;
    } else {
      selectedRow.discount = _.clone(selectedRow.discountStafRabDefault);
      selectedRow.mediaDiscount1 = _.clone(selectedRow.mediaDiscount1Default);
      selectedRow.mediaDiscount2 = _.clone(selectedRow.mediaDiscount2Default);
    }
  }

  apply = () => {
    const selectedChildRows = this.getSelectedChildRows();
    let totalFinalPrice = 0;
    if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].negotiationTypeId ==  '-1' || this.footerRowData[0].negotiationTypeId ==  -1 || this.footerRowData[0].applyIndex == null ||  !this.footerRowData[0].negotiationTypeId) {
        this.toaster.logInfo(this.userBundle['discount.br.footerSelectionError'] || 'Select footer values first!');
        return;
      }
      for (const row of selectedChildRows) {    
        if (this.footerRowData[0].applyIndex === 'true' && this.campaignDetails.paidNegotiationTypes.includes(parseInt(this.footerRowData[0].negotiationTypeId))) {              
          row.applyIndex = true;
          row.networkIndexValue = row.originalNetworkIndexValue;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        } else if (this.footerRowData[0].applyIndex === 'false') {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        } else if (!this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId))) {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        }
      }
      this.updatePriceForSameWeekNumberRows(true, selectedChildRows,this.footerRowData[0].applyIndex);
      for (const row of selectedChildRows) {                
        row.negotiationTypeId = this.footerRowData[0].negotiationTypeId;
        if (!this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId))) {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        }        
      }
      for (const row of this.rowData) {        
        totalFinalPrice = totalFinalPrice + Number(row.finalPrice);
      }
      this.rowData.forEach(temprow => {
        temprow.totalFinalPrice = totalFinalPrice.toFixed(this.precisionOnPrice);
      })
      this.gridOptions.api.redrawRows();
      this.reset();
    } else {
      this.toaster.logInfo(this.userBundle['discount.at.selectionError'] || 'Select atleast one row!');
    }
  }


  reset = () => {
    this.resetApply();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {    
    //this.footerRowData[0]['negotiationTypeId'] = false;
    this.footerRowData[0]['applyIndex'] = 'false';
    this.footerRowData[0].negotiationTypeId = -1;
  }

  groupingCellRenderer(params) {
    return params.data.childCount ? params.data.childCount : '';
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLoc = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLoc);
  }

  // Data functions
  getChildRows(groupingId?: string, filterOnly = true): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getSelectedChildRows(groupingId?: string, filterOnly = false): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData, this.groupingField, groupingId, filterOnly);
  }

  getParentRows(groupingId?: string, data?: any[], groupingField?: string): any[] {
    const newData = data || this.rowData;
    return AgCustomRowUtil.getParentRows(newData, groupingField, groupingId);
  }

  sumFieldValues(rows: any[], fieldName: string): number {
    return AgCustomRowUtil.sumFieldValues(rows, fieldName);
  }

  calculateGridSummaryValues() {
    this.totalFrames = this.getTotalOfSelectedProperty('numberFrames');
    this.totalGrossPrice = this.getTotalOfSelectedProperty('grossPrice');
  }

  getTotalOfSelectedProperty(propertyName: string, filterOnly = false): number {
    const rows = AgCustomRowUtil.getSelectedChildRows(this.rowData, null, null, filterOnly);
    return AgCustomRowUtil.sumFieldValues(rows, propertyName);
  }

  /*
  * download cart data in excel format
  */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      for (let j = 1; j < this.columnDefs.length; j++) {
        let cellVal = row[this.columnDefs[j].field];
        if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
          cellVal = row[`${this.columnDefs[j].field}_sort`];
          if (this.columnDefs[j].field === 'negotiationTypeId' && !row[`${this.columnDefs[j].field}_sort`]) {
            const negotiationType = this.negotiationTypes.find(type => String(type.negotiationTypeId) === String(row[this.columnDefs[j].field]));
            cellVal = negotiationType ? negotiationType.negotiationTypeName : row[this.columnDefs[j].field];
          }
        }
        if ((this.columnDefs[j].field === 'startDate' || this.columnDefs[j].field === 'endDate') && cellVal) {
          cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
        }
        const cell: ExcelCell = {
          text: cellVal,
          fontColor: '#000000',
          bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
          borderColor: '#CCCCCC'
        };
        sheetRow.push(cell);
      }
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

  
  findRowsWithSameWeekNumber<T extends any>(array: T[], property: keyof T): T[] {
    // Group objects by the specified property
    const grouped = _.groupBy(array, item => item[property]);

    // Filter groups to find duplicates (groups with more than one item)
    const duplicates = _.flatMap(grouped, (items) => {
        // Only return items if there are duplicates
        return items.length > 1 ? items : [];
    });
    return duplicates;
  }

  updatePriceForSameWeekNumberRows(isFromFooter: boolean, selectedChildRows: any | [], applyIndex: string) {
    // Find the duplicate rows from DG 
    if (isFromFooter) {
      if (selectedChildRows.length > 0) { 
        let duplicateRows = this.findRowsWithSameWeekNumber(this.rowData, 'weekNumberOfYear');
        let selectedRowsCount = {};
        selectedChildRows.forEach(item => {
          selectedRowsCount[item.weekNumberOfYear] = (selectedRowsCount[item.weekNumberOfYear] || 0) + 1;
        });
          let duplicateRowsCount = {};
          duplicateRows.forEach(item => {
            if (item && item.weekNumberOfYear !== undefined && item.weekNumberOfYear !== null) {
              duplicateRowsCount[item.weekNumberOfYear] = (duplicateRowsCount[item.weekNumberOfYear] || 0) + 1;
            }
          });
          for (let week in selectedRowsCount) {
            if (duplicateRowsCount[week] === selectedRowsCount[week]) {
              duplicateRows.forEach(item => {
                if (item.weekNumberOfYear !== undefined && item.weekNumberOfYear !== null && item.weekNumberOfYear == week) {
                  if (applyIndex == 'true' && this.campaignDetails.paidNegotiationTypes.includes(parseInt(this.footerRowData[0].negotiationTypeId))) {
                    item.applyIndex = true;
                    item.networkIndexValue = item.originalNetworkIndexValue;
                    item.finalPrice = (item.grossPrice * (item.networkIndexValue / 100)).toFixed(this.precisionOnPrice);
                  }
                }
              });
            } else {
                const weekItems = duplicateRows.filter(dupItem => dupItem.weekNumberOfYear === parseInt(week));
                const hasFreeNegotiationType  = weekItems.some(dupItem => this.campaignDetails.paidNegotiationTypes.includes(parseInt(dupItem.negotiationTypeId)) === false);
                if (hasFreeNegotiationType ) {
                  weekItems.forEach(dupItem => {
                    dupItem.applyIndex = false;
                    dupItem.networkIndexValue = 100;
                    dupItem.finalPrice = (dupItem.grossPrice * (dupItem.networkIndexValue / 100)).toFixed(this.precisionOnPrice);
                  });
                }
                else{
                  if(!this.campaignDetails.paidNegotiationTypes.includes(parseInt(this.footerRowData[0].negotiationTypeId))) {
                    weekItems.forEach(dupItem => {
                      dupItem.applyIndex = false;
                      dupItem.networkIndexValue = 100;
                      dupItem.finalPrice = (dupItem.grossPrice * (dupItem.networkIndexValue / 100)).toFixed(this.precisionOnPrice);
                    });
                  } else {
                    weekItems.forEach(dupItem => {
                      dupItem.applyIndex = applyIndex == 'false' ? false : true;
                      dupItem.networkIndexValue = applyIndex == 'false' ? 100 : dupItem.originalNetworkIndexValue;
                      dupItem.finalPrice = (dupItem.grossPrice * (dupItem.networkIndexValue / 100)).toFixed(this.precisionOnPrice);
                    });
                  }
                }   
            }
          }
      }
    } else {
      const duplicateRows = this.findRowsWithSameWeekNumber(this.rowData, 'weekNumberOfYear');
      const hasFreeNegotiationType = duplicateRows.some(row => row.weekNumberOfYear === selectedChildRows.weekNumberOfYear && row.negotiationTypeId !== null && !this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId)));
      duplicateRows.forEach(row => {
      if (applyIndex === 'true' && this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId)) && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
        row.applyIndex = true;          
        row.networkIndexValue = row.originalNetworkIndexValue;
        row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
      } else if (applyIndex === 'false' && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);        
      }  else if (!this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId)) && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
        row.applyIndex = false;          
        row.networkIndexValue = 100;
        row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
      }
      if (row.negotiationTypeId !== null && hasFreeNegotiationType && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
        row.applyIndex = false;
        row.networkIndexValue = 100;
        row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
      }
    }); 
      let otherRows = [];   
      let tmpRowData = _.clone(this.rowData);
      tmpRowData.forEach(item => {
        if (!duplicateRows.includes(item)) {
          otherRows.push(item);
        }
      });
      otherRows.forEach(row => {
        if (applyIndex === 'true' && this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId)) && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
          row.applyIndex = true;
          row.networkIndexValue = row.originalNetworkIndexValue;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        } else if (applyIndex === 'false' && row.weekNumberOfYear === selectedChildRows.weekNumberOfYear) {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        } else if (!this.campaignDetails.paidNegotiationTypes.includes(parseInt(row.negotiationTypeId)) && selectedChildRows.weekNumberOfYear === row.weekNumberOfYear) {
          row.applyIndex = false;          
          row.networkIndexValue = 100;
          row.finalPrice = (row.grossPrice * (row.networkIndexValue/100)).toFixed(this.precisionOnPrice);
        }
      });  
    }    
  }
  
  onSelectChange(selectedChildRows, applyIndex) {
    let totalFinalPrice = 0;
    if (selectedChildRows.length > 0) {
        for (let index = 0; index < selectedChildRows.length; index++) {
          this.updatePriceForSameWeekNumberRows(false, selectedChildRows[index], String(selectedChildRows[index].applyIndex));           
        }
    }
    for(const row of this.rowData){
      totalFinalPrice = totalFinalPrice + Number(row.finalPrice);
    }
    this.rowData.forEach(temprow => {
      temprow.totalFinalPrice = totalFinalPrice.toFixed(this.precisionOnPrice);
    })
    return null;
  }

  cellDisable(negotiationTypeId: number) {
    return (!negotiationTypeId || negotiationTypeId === -1) || !this.campaignDetails.paidNegotiationTypes.includes(negotiationTypeId);
  }
}
