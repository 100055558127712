import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DndDropEvent } from './../../../../../scripts/3rdParty/ngx-drag-drop-custom';
import { Router } from '@angular/router';
import { BrickBaseService } from '../core/services/brick-base.service';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import {
  CellValues,
  Row, Cell,
  DateRange,
  ColumnHelper,
  ColumnSummary,
  ProductHelper
} from './../models/workspace/index';
import { LogHelperService } from './../core/services/log-helper.service';
import { WorkspaceService } from './../core/services/workspace.service';
import { ReshuffleService } from './reshuffle.service';
import { WorkspaceActionButtonService } from './workspace.action.button.service';
import { SbModalPopupService } from './../core/components/sb-modal-popup/sb-modal-popup.service';
import { CampaignSplitComponent } from './modal-windows/campaign-split/campaign-split.component';
import { ReshuffleProcessComponent } from './modal-windows/reshuffle-process/reshuffle-process.component';
import { ReshuffleConfirmComponent } from './modal-windows/reshuffle-confirm/reshuffle-confirm.component';
import { CellPosition, ExpandDirection } from '../models/workspace/cell-position';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from './../core/services/state.service';
import { SystemFlags } from '../models';
import { DataShareService } from '../core/services/data-share.service';
import * as _ from 'lodash';
import { HistoryStackService } from '../core/services/history-stack.service';
import { SyncWsBricksService } from './../core/services/sync-ws-bricks.service';
import { WorkspaceBase } from './workspace-base';
import { HistoryStackData } from '../models/workspace/historyStackData';
import { AppNameEnum } from '../core/enum';
import { CellAttributeService, PcmService } from '../core/services';
import { Subscription } from 'rxjs';
import { Category } from '../models/bricsMasterData';
import { GLOBAL } from '../core/utils/app.constant';
import { ColumnConfigs, ColumnConfig } from '../models/MapData.model';
import { Brick } from './brick-model';
import { ObjectiveService, ObjectiveModeForEnum } from './objective/objective.service';
import { ReshuffleDetailLSComponent } from './modal-windows/reshuffle-detail-ls/reshuffle-detail-ls.component';
import { ReshuffleDetailLSResultComponent } from './modal-windows/reshuffle-detail-ls-result/reshuffle-detail-ls-result.component';
import * as moment from 'moment';
import { ContinueWorkComponent } from './modal-windows/continue-work/continue-work.component';
import { ConfirmationModalComponent } from '../result/confirmation-modal/confirmation-modal.component';
import { MultiTargetService } from '../bricks/multi-target/multi-target.service';
import { ContextMenuModel } from '../models/contextMenu.model';

class WaterMark {
  campaignReference?: string;
  agency?: string;
  advertiser?: string;
}


@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css', './workspace-pcm.css'],
  providers: [SbModalPopupService, MultiTargetService],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animatedmessage', [
      state('in', style({ opacity: 0.7 })),
      state('out', style({ opacity: 0 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class WorkspaceComponent extends WorkspaceBase implements OnInit, OnDestroy {

  waterMark: WaterMark = new WaterMark();
  margin: number;
  get incorrectSolution() {
    return SystemFlags.incorrectSolution;
  }
  @ViewChild('zoomReset') zoomReset;
  columnHelper: ColumnHelper;
  private subscriptions: Subscription[] = [];
  showMessageTimeout;
  rotateMessageTimeout;
  /**
   * @description This is needed for reshuffling as reshuffle should be called for first time campaign load
   * @private
   * @memberof WorkspaceComponent
   */
  private iscolumnSummarySubCalled = false;
  lockedMessageStatus = false;
  public global = GLOBAL;
  isLanguageChanged = false;
  showExportMenu = false;
  isProximityExportAllowed = false;
  lsProcessCompleted = false;
  showLsMessages = false;
  scrHeight = window.innerHeight;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }
  kpiData;
  reshuffleMessage: string;

  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  copiedCell: Cell = null;
  copiedBrickID: Brick;
  copiedRowIndex: number;
  /**
   *Creates an instance of WorkspaceComponent.
   * @author Amit Mahida
   * @memberof WorkspaceComponent
   */
  constructor(
    private sbModalPopupService: SbModalPopupService,
    public brickBaseService: BrickBaseService,
    public dataShareService: DataShareService,
    public stateService: StateService,
    public logHelperService: LogHelperService,
    public workspaceActionButtonService: WorkspaceActionButtonService,
    public workspaceService: WorkspaceService,
    public historyStackService: HistoryStackService,
    private router: Router,
    private syncWsBricksService: SyncWsBricksService,
    private pcmService: PcmService,
    public appHeaderService: AppHeaderService,
    private reshuffleService: ReshuffleService,
    private multiTargetService: MultiTargetService,
    private objectiveService: ObjectiveService,
    private changeDetectorRef: ChangeDetectorRef,
    private cellAttributeService: CellAttributeService,
  ) {
    super(brickBaseService, dataShareService, stateService, appHeaderService);
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.isLanguageChanged = true;
        this.filter = this.stateService.getWorkspaceFilterObj();
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
    this.columnSummarySubscription = this.stateService.columnSummary$.subscribe((newColumnSummary) => {
      if (newColumnSummary && Object.keys(newColumnSummary).length > 0) {
        this.columnSummarys = this.filter.generateColumnSummary(newColumnSummary);
        this.filter.reRenderQuantityBrick();
        // to prevent reshuffle to call on first time campaign load
        // Reshuffle should only be called after processBrics
        if (this.iscolumnSummarySubCalled) {
          this.filter.checkToleranceReshuffleBricks();
          this.filter.populateReshufflingColumns(false, null);
          this.filter.populateToleranceLimitCellAdd();
        }
      } else {
        this.columnSummarys = [];
        this.filter.columnSummaryWithoutBrick = [];
      }
      this.iscolumnSummarySubCalled = true;
    });
  }

  onDocumentClick() {
    if (event.target !== document.getElementsByClassName('exportMenu')[0]) {
      this.showExportMenu = false;
    }
  }

  ngOnInit() {
    this.appHeaderService.headerMsgStatus$.subscribe((status: boolean) => {
      this.lockedMessageStatus = status;
      this.appHeaderService.appHeaederHeight$.subscribe((num: number) => {
        this.margin = num / 2;
      })
    });
    this.dataShareService.appName = AppNameEnum.workspace;
    this.onInit();
    this.pcmService.userBundle = this.userBundle;
    this.filter.initialConfig = this.dataShareService.getInitialConfig();
    const productDetails: ProductHelper[] = this.stateService.getWorkspaceObject('productDetails');
    if (productDetails && productDetails.length > 0) {
      this.filter.productDetails = productDetails;
      this.filter.displayBricksInLeftPanel(); // SM-1884
    } else {
      this.masterData.forEach((category: Category) => {
        category.brics.forEach((brick: Brick) => {
          brick.optionalForProduct = false;
        });

        if (this.filter.initialConfig.userData.externalUser) {
          category.hide = category.brics.filter(b => b.hide && !b.optionalForProduct).length === category.brics.length;
        }
      });
    }
    if (SystemFlags.isFillerCampaign) {
      this.handleBricsForFillerCampaign();
    }

    const productRow: Row = this.filter.rows.find(e => e.bric.bricid === this.brickBaseService.brickID.ProductCatalogue);

    if (productRow) {
      const productsUsedInCampaign = productRow.cells.filter((cell: Cell) => cell.selected && !_.isEmpty(cell.selected));
      const productsUsedInCampaignCount = productsUsedInCampaign.length;
      if (productsUsedInCampaignCount > 0 && (SystemFlags.isLoadedCampaign || SystemFlags.isCloneCampaign) &&
        this.filter.productDetails.length !== productsUsedInCampaignCount) {
        this.filter.updateProductValidationsOnLoad(productsUsedInCampaign, productsUsedInCampaignCount);
      }

      if (productsUsedInCampaignCount > 0) {
        const inchargeRowIndex = this.filter.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
        this.filter.pcmService.markProductBricInvalid(
          this.filter.rows,
          this.stateService.getCampaign(),
          inchargeRowIndex, false, this.stateService);

        productRow.cells.forEach((col: Cell) => {
          if (!col.isEmpty && !col.isHidden && !col.isValid) {
            this.filter.markWorkspaceInvalid();
          }
        });

        this.filter.disableMandatoryProductBricksOnLoad();
      }
    }
    this.populateWaterMark();
    this.filter.lockAllButton = this.filter.isAllColumnsLocked();
    this.readOnlyModal = SystemFlags.readOnly || SystemFlags.readOnlyWorkspace;
    if (!this.isLanguageChanged) {
      this.backupFilter = this.stateService.clone(this.filter);
    }
    this.subscriptions.push(this.stateService.masterDataSubject.subscribe((masterData: Category[]) => {
      this.masterData = masterData['category'];
    }));
    if (this.filter.toleranceLimit.cellAddress.length !== 0) {
      this.dataShareService.activateResultTab(false);
      this.dataShareService.activateCommercialTab(false);
    }

    if (SystemFlags.isLoadedCampaign) {
      if (SystemFlags.campaignLoadError || SystemFlags.campaignLoadError !== '') {
        this.logHelperService.logError(SystemFlags.campaignLoadError);
      }
    }
    if (SystemFlags.isRangeSelectionMandatory) {
      // SM-7855 : If campaign is clone campaign and range selection is mandatory then Range modal will be appear forcefully
      setTimeout(() => {
        const inchargeRowIndex = this.filter.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
        this.onBrickDblClick(this.filter.rows[inchargeRowIndex], this.filter.rows[inchargeRowIndex].cells[0]);
      }, 100);
    }

    this.proximityAvailable();
    document.addEventListener('click', () => this.onDocumentClick());
  }

  displayContextMenu(event: MouseEvent, rowIndex, colIndex) {
    event.preventDefault();
    this.documentClick();
    if ((this.filter.rows[rowIndex]?.cells[colIndex]?.isEmpty &&
      !this.filter.rows[rowIndex]?.cells[colIndex]?.isHidden) && this.copiedCell != null &&
      this.copiedBrickID != null && this.copiedCell.rowIndex == rowIndex) {
      this.isDisplayContextMenu = true;
      this.rightClickMenuItems = [
        {
          menuText: 'paste',
          menuEvent: 'paste',
          rowIndex: rowIndex,
          colIndex: colIndex
        },
      ];
    } else if (this.brickBaseService.copyPasteBricks.indexOf(this.filter.rows[rowIndex].bric.bricid) > -1
      && !this.filter.rows[rowIndex].cells[colIndex].isEmpty) {
      this.isDisplayContextMenu = true;
      this.rightClickMenuItems = [
        {
          menuText: 'copy',
          menuEvent: 'copy',
          rowIndex: rowIndex,
          colIndex: colIndex
        },
      ];
    } else {
      return;
    }

    let calculatedX = colIndex * 160;
    let calculatedY = rowIndex * 90;
    this.rightClickMenuPositionX = (event.offsetX + 115) + calculatedX;
    this.rightClickMenuPositionY = (event.offsetY + 10) + calculatedY;
  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`
    }
  }

  handleMenuItemClick(event) {
    switch (event.data) {
      case "copy":
        this.copiedCell = _.cloneDeep(this.filter.rows[this.rightClickMenuItems[0].rowIndex].cells[this.rightClickMenuItems[0].colIndex]);
        this.copiedBrickID = this.filter.rows[this.rightClickMenuItems[0].rowIndex].bric;
        this.copiedRowIndex = this.rightClickMenuItems[0].rowIndex;
        break;
      case "paste":
        this.copiedCell.cellIndex = this.rightClickMenuItems[0].colIndex;
        this.copiedCell.rowIndex = this.copiedRowIndex;
        this.copiedCell.cellWidth = 1;
        this.copiedCell.isMandatoryForProduct = false;
        const columnHelper = this.stateService.getColumnHelper();
        const isLocked = (columnHelper && columnHelper[this.rightClickMenuItems[0].colIndex]) ? true : false;
        this.copiedCell.isLocked = isLocked;
        this.copiedCell.isEditable = true;
        const cellPosition: CellPosition = {
          rowIndex: this.copiedCell.rowIndex,
          cellIndex: this.copiedCell.cellIndex,
          targetRowIndex: -1,
          note: "",
          expandDirection: ExpandDirection.None
        }
        const dropCondition: { isValid: boolean, note: string } =
          this.filter.checkIfCellIsAllowedAtPosition(cellPosition, this.copiedBrickID, false, ExpandDirection.None, true, this.rightClickMenuItems[0]?.colIndex);
        if (dropCondition.isValid) {
          this.historyStackService.pushInHistoryStack(new HistoryStackData(this.filter));
          this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
          this.historyStackService.pushColumnHelperInHistoryStack(columnHelper);
          this.filter.rows[this.copiedCell.rowIndex].cells[this.rightClickMenuItems[0].colIndex] = this.copiedCell;
          this.filter.generateBrickRequestJSON();
          this.filter.processBrics();
          this.copiedCell = null;
          this.copiedBrickID = null;
        } else {
          this.copiedCell = null;
          this.copiedBrickID = null;
          this.logHelperService.logError(dropCondition.note);
        }
        break;
      default:
        break;
    }
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
    this.rightClickMenuItems = [];
  }

  /**
   * @description - callback method call on navigating to other page
   * @author Alkesh Shah
   * @returns {boolean}
   * @memberof WorkspaceComponent
   */
  beforeNavigate(): boolean {
    if (GLOBAL.BRIC_CAMPAIGN_ID >= 0 && !SystemFlags.incorrectSolution) {
      const stateChangeCondition = ((this.backupFilter.rows.length > 0 || this.filter.rows.length > 0) &&
        (JSON.stringify(this.backupFilter.rows) !== JSON.stringify(this.filter.rows)));
      if (!SystemFlags.getBasketDataForGP) {
        SystemFlags.getBasketDataForGP = stateChangeCondition ? true : false;
      }
      if (!SystemFlags.getBasketDataForVP) {
        SystemFlags.getBasketDataForVP = stateChangeCondition ? true : false;
      }
    } else {
      SystemFlags.getBasketDataForGP = false;
      SystemFlags.getBasketDataForVP = false;
    }
    return true;
  }

  /**
   * Method to open workspace in new tab
   * Mainly used when LS Reshufflling process is going on and user wants to continue work
   * @author Vijay Sutaria
   * @date 18-11-2021
   */
  openNewTab() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: 'continue-work-modal'
    };
    const resolveObject = {
      // message: this.userBundle['reshuffle.confirm.message']
    };
    this.sbModalPopupService.open(ContinueWorkComponent, resolveObject, modalOptions).result.then(() => {
      const url = location.origin;
      window.open(url, '_blank').focus();
    }, (reason) => {
      console.log(reason);
    });
  }

  /**
   * Required to change the export behavior if proximity is dropped
   */
  proximityAvailable() {
    const pRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Proximity));
    this.isProximityExportAllowed = false;
    if (pRow) {
      pRow.cells.forEach((cell) => {
        if ((cell.selected.pointOfInterest && cell.selected.pointOfInterest.selectedModelValues && cell.selected.pointOfInterest.selectedModelValues.length)
          || (cell.selected.points && cell.selected.points.selectedModelValues && cell.selected.points.selectedModelValues.length)) {
          this.isProximityExportAllowed = true;
          return;
        }
      });
    }
    const objectiveBricRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Objective));
    if (objectiveBricRow && objectiveBricRow.cells) {
      objectiveBricRow.cells.forEach((cell) => {
        if (cell.selected && cell.selected.objectives) {
          const objectiveProximityRow: Row = cell.selected.objectives.find((row: Row) => {
            return row['bric'].bricid === Number(this.brickBaseService.brickID.Proximity);
          });
          if (objectiveProximityRow) {
            objectiveProximityRow.cells.forEach((objectiveCell) => {
              if ((objectiveCell.selected.pointOfInterest && objectiveCell.selected.pointOfInterest.selectedModelValues && objectiveCell.selected.pointOfInterest.selectedModelValues.length)
                || (objectiveCell.selected.points && objectiveCell.selected.points.selectedModelValues && objectiveCell.selected.points.selectedModelValues.length)) {
                this.isProximityExportAllowed = true;
                return;
              }
            });
          }
        }
      });
    }
  }

  /**
   * @description Handes drop brick event on workspace
   * @author Amit Mahida
   * @param {DndDropEvent} event
   * @param {number} [rowIndex]
   * @param {number} [colIndex]
   * @param {boolean} [isGhost=false]
   * @returns void
   * @memberof WorkspaceComponent
   */
  onDrop(event: DndDropEvent, rowIndex?: number, colIndex?: number, isGhost = false) {
    if (this.filter.checkIfCellExistsInColumn(event.data.cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true) !== -1) {
      this.logHelperService.logError(this.userBundle['common.error.notallowed']);
      return true;
    }
    if (isGhost && event.data.bricid !== this.brickBaseService.brickID.ProductCatalogue) {
      this.logHelperService.logError(this.userBundle['workspace.drop.onghostbrick.error']);
      return true;
    }
    switch (event.dropEffect) {
      case 'copy':
        if (event.data) {
          this.sendGAEvent(event.data.bricid);
          const cellPosition: CellPosition = this.filter.dropBrick(event.data, rowIndex, colIndex, this.expandDirection, false, isGhost);

          if (cellPosition.rowIndex !== -1) {
            try {
              if (!this.filter.isMandatoryBrick(event.data.bricid) && !this.filter.isMandatoryBricksAvailable(cellPosition.cellIndex)
                && this.brickBaseService.bypassBricks.indexOf(event.data.bricid) === -1
                && (cellPosition.cellIndex === 0 || !this.filter.willColumnExpand(cellPosition.cellIndex))) {
                this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
                return;
              }
              const cellValues: CellValues = new CellValues();
              cellValues.brick = { ...event.data, ...cellPosition };
              this.openBricModalWindow(cellValues);
            } catch (e) {
              this.logHelperService.logError(e);
            }
          } else {
            this.logHelperService.logError(cellPosition.note);
          }
        }
        break;
      case 'move':
        if (event.data && rowIndex !== null && rowIndex !== undefined) {
          this.sendGAEvent(this.filter.rows[rowIndex].bric.bricid);
          this.filter.moveBrick(event.data, rowIndex, colIndex, this.expandDirection);
        } else {
          this.logHelperService.logInfo(this.userBundle['common.error.notallowed']);
        }
        break;
      default:
        break;
    }
    this.expandDirection = ExpandDirection.None;
    this.enableAllMasterBrics();
    this.showRemoveBar = false;
  }

  /**
   * @description Bric multiple drop
   * @author Darshan Vachhani
   * @param {DndDropEvent} event
   * @param {number} [rowIndex]
   * @returns
   * @memberof WorkspaceComponent
   */
  onMultidropQtyBrick(event: DndDropEvent, rowIndex: number) {
    switch (event.dropEffect) {
      case 'copy':
        if (event.data) {
          if (event.data.bricid == this.brickBaseService.brickID.SOT ||
            event.data.bricid == this.brickBaseService.brickID.Frame ||
            this.filter.isQuantityBric(event.data.bricid)
          ) {
            this.filter.dropMultipleBrics(event.data);
          } else {
            this.logHelperService.logError(this.userBundle['workspace.error.mutlidrop']);
          }
        }
        break;
      case 'move':
        if (event.data && rowIndex !== null && rowIndex !== undefined) {
          this.sendGAEvent(this.filter.rows[rowIndex].bric.bricid);
          if (event.data.index !== rowIndex) {
            this.filter.swapRow(event.data.index, rowIndex);
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * @description Redo last undone step
   * @author Amit Mahida
   * @returns
   * @memberof WorkspaceComponent
   */
  redoLastUndoneStep() {
    if (this.filter.isPastColumnHidden) {
      this.logHelperService.logError(this.userBundle['worksapce.error.hidden']);
      return;
    }

    const oldItem = this.historyStackService.popFromUndoneHistoryStack(this.stateService.getFreshFilterObj());
    const oldColumnHelper = this.historyStackService.popFromUndoColumnHelperHistoryStack();

    if (oldItem) {
      // Push the item into Undo stack
      // Used when we do Undo then again Redo (eg: To push into Undo stack from Redo stack)
      this.historyStackService.pushInHistoryStackRedo(new HistoryStackData(this.filter));
      this.historyStackService.pushColumnHelperInHistoryStackRedo(this.stateService.getColumnHelper());

      this.stateService.setColumnHelper(oldColumnHelper);
      // Set current workspace item to poped workspace item from Redo Stack
      this.filter = oldItem;
      this.filter.refresh();
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this.filter));

      if (this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.ProductCatalogue))) {
        this.filter.reIndexingProductDetails();
      } else {
        this.filter.productDetails = [];
        this.stateService.setWorkspaceObject('productDetails', this.filter.productDetails);
      }

      const oldColumnLineNumber = this.historyStackService.popFromUndoColumnLineNumberHistoryStack();
      if (oldColumnLineNumber) {
        this.historyStackService.pushColumnLineNumberInHistoryStackRedo(this.stateService.getWorkspaceObject('columnLineNumber'));
        this.stateService.setWorkspaceObject('columnLineNumber', _.cloneDeep(oldColumnLineNumber));
      }

      // After completing undo process need to send request to the backend
      if (this.filter.rows.length) {
        // As we directly store filter into History Stack.
        // No need to call generateBrickRequestJSON method just need to process the Bricks
        this.filter.processBrics();
      } else {
        this.filter.setWorkspaceValidity(false);
        this.filter.columnSummaryWithoutBrick = [];
      }

      if (!this.filter.isMandatoryBricksAvailable()) {
        this.dataShareService.activateResultTab(false);
        this.dataShareService.activateCommercialTab(false);
      }
      this.filter.displayBricksInLeftPanel();
    }
    this.proximityAvailable();
  }

  /**
   * @description Undo last step
   * @author Amit Mahida
   * @returns
   * @memberof WorkspaceComponent
   */
  undoLastStep() {
    if (this.filter.isPastColumnHidden) {
      this.logHelperService.logError(this.userBundle['worksapce.error.hidden']);
      return;
    }
    const oldItem = this.historyStackService.popFromHistoryStack(this.stateService.getFreshFilterObj());
    const oldColumnHelper = this.historyStackService.popColumnHelperInHistoryStack();

    if (oldItem) {
      this.historyStackService.pushInUndoneHistoryStack(new HistoryStackData(this.filter));
      this.historyStackService.pushInUndoColumnHelperHistoryStack(this.stateService.getColumnHelper());
      this.stateService.setColumnHelper(oldColumnHelper);
      this.workspaceService.setDefaultPanning();

      // Set current workspace item to previous workspace item
      this.filter = oldItem;
      this.filter.refresh();

      if (this.filter.getMarkSelectedCount() > 0) {
        this.filter.removeMarkSelected();
      }
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(this.filter));
      this.filter.removeUnUsedColumnConfigs();

      if (this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.ProductCatalogue))) {
        this.filter.reIndexingProductDetails();
      } else {
        this.filter.productDetails = [];
        this.stateService.setWorkspaceObject('productDetails', this.filter.productDetails);
      }

      const oldColumnLineNumber = this.historyStackService.popColumnLineNumberInHistoryStack();
      if (oldColumnLineNumber) {
        this.historyStackService.pushInUndoColumnLineNumberHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
        this.stateService.setWorkspaceObject('columnLineNumber', _.cloneDeep(oldColumnLineNumber));
      }

      // After completing undo process need to send request to the backend
      if (this.filter.rows.length) {
        // As we directly store filter into History Stack.
        // No need to call generateBrickRequestJSON method just need to process the Bricks
        this.filter.processBrics();
      } else {
        this.filter.setWorkspaceValidity(false);
        this.filter.columnSummaryWithoutBrick = [];
      }

      if (!this.filter.isMandatoryBricksAvailable()) {
        this.dataShareService.activateResultTab(false);
        this.dataShareService.activateCommercialTab(false);
      }

      this.filter.displayBricksInLeftPanel();
    }

    this.proximityAvailable();
  }

  /**
   * @description Method to open the modal popup for Split Campaign
   * @author Amit Mahida
   * @param {Cell} cell
   * @returns
   * @memberof WorkspaceComponent
   */
  splitCampaign(cell: Cell) {
    // CASE 1: Do not allow to open Incharge Modal if user drop Split button on brics other than Range bric.
    const isInchargeCell = this.filter.rows[cell.rowIndex].bric.bricid === this.brickBaseService.brickID.Incharge;
    if (!isInchargeCell) {
      this.logHelperService.logError(this.userBundle['workspace.split.validation.splitnotallowed']);
      return;
    }

    // CASE 2: Do not allow to open Incharge Modal if user drop Split button on past date Range.
    if (isInchargeCell && !((cell.selected.current || cell.selected.futureSplitInterval) && this.splitable)) {
      this.logHelperService.logError(this.userBundle['workspace.split.validation.splitnotpossible']);
      return;
    }

    const resolveObject = {
      'selected': cell.selected
    };
    const modalOptions: NgbModalOptions = {
      windowClass: 'modal-backdrop Incharge'
    };
    this.sbModalPopupService.open(CampaignSplitComponent, resolveObject, modalOptions).result.then((splitDetails) => {
      this.historyStackService.pushInHistoryStack(new HistoryStackData(this.filter));
      this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());

      splitDetails.columnIndex = cell.cellIndex;
      this.workspaceService.getCampaignSplitData(splitDetails,
        this.dataShareService.getServiceCallUrlByKey('CAMPAIGN_SPLIT_URL')).subscribe((response: any) => {
          if (response && response.status === 'OK') {
            const data = response.data;
            this.filter.updateLookupData(response.data.bricsData, response);
            SystemFlags.readOnlyWorkspace = data.readOnlyWorkspace;
            SystemFlags.splitable = this.splitable = data.splitable;
            SystemFlags.splitted = true;
            this.allowSplit = false;
            this.historyStackService.resetHistoryStack();
            SystemFlags.readOnly = data.readOnly;
            this.readOnlyModal = SystemFlags.readOnly || SystemFlags.readOnlyWorkspace;
            const campaignDetail = this.stateService.getCampaign();
            if (campaignDetail.invoiceLocked) {
              SystemFlags.readOnly = true;
              SystemFlags.readOnlyWorkspace = true;
            }
            this.handleUserLocked(data.columnHelper);
            data.bricsData = _.sortBy(data.bricsData, 'row');
            data.bricsData = this.workspaceService.updateExplodedColumnsInProductAfterSplit(data.bricsData, splitDetails.columnIndex);
            if (!data.columnSummary) {
              data.columnSummary = this.stateService.getAllColumnSummary();
            }
            // hide lock message from header
            this.appHeaderService.changeLockedMessage(null);
            this.syncWsBricksService.bricksDataCalculations(data.bricsData, data);
            /* Bricks Data Calculations get and sets the Filter Object in StateServie,hence we need
             to update the current filter from the state service */
            this.filter = this.stateService.getWorkspaceFilterObj();
            // Moved code from bricksDataCalculations as below code needs to be called on on split
            const productRow: Row = this.filter.rows.find(e => e.bric.bricid === this.brickBaseService.brickID.ProductCatalogue);
            if (productRow) {
              const productsUsedInCampaign = productRow.cells.filter((tmpCell: Cell) => tmpCell.selected && !_.isEmpty(tmpCell.selected));
              const productsUsedInCampaignCount = productsUsedInCampaign.length;

              if (productsUsedInCampaignCount > 0 && SystemFlags.isLoadedCampaign) {
                this.filter.updateProductValidationsOnLoad(productsUsedInCampaign, productsUsedInCampaignCount);
              }
            }

            SystemFlags.incorrectSolution = false;
            this.filter.setWorkspaceValidity(true);
            this.filter.productDetails = [];
            this.filter.populateReshufflingColumns(false, null);
            this.filter.populateToleranceLimitCellAdd();
            this.forcefullyCallProcessBrics();

            if (this.filter.checkFtgIterationAllowed()) {
              for (const row of this.filter.rows) {
                if (row.bric.bricid === this.brickBaseService.brickID.Volume) {
                  for (const cellInner of row.cells) {
                    const columnSummary = this.stateService.getColumnSummary(cellInner.cellIndex.toString());
                    if (cellInner.selected && cellInner.requestJSON && cellInner.requestJSON.selectionAudienceReduction) {
                      cellInner.selected.actualFtgAllocated = _.clone(columnSummary.secondaryImpression);
                      cellInner.requestJSON.selectionAudienceReduction.actualFtgAllocated = _.clone(cellInner.selected.actualFtgAllocated);
                      cellInner.toolTipText = this.cellAttributeService.getToolTip(
                        this.brickBaseService.brickID.Volume,
                        cellInner.selected
                      );
                    }
                  }
                }
              }
            }
            this.filter.lockAllButton = this.filter.isAllColumnsLocked();
          } else if (response.status === 'KO') {
            this.logHelperService.logError(response.message || this.userBundle['worksapce.error.solutionNotPossible']);
          } else {
            if (response.message) {
              this.logHelperService.logError(response.message);
              return;
            }
            this.logHelperService.logError(this.userBundle['worksapce.error.solutionNotPossible']);
          }
        });
    }, () => {
      this.allowSplit = false;
    });

  }

  /**
   * @description call back function - called on dbl click on bric
   * @author Alkesh Shah
   * @param {Row} row - row object of
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  onBrickDblClick(row: Row, cell: Cell): void {
    // if bric is mandaroty dont allow to open
    if (!SystemFlags.isRangeSelectionMandatory && !this.checkIfBricallowedToOpen(cell, Number(row.bric.bricid))) {
      return;
    }
    const cellValues: CellValues = new CellValues();
    cellValues.brick = _.clone(row.bric);
    cellValues.brick.cellIndex = cell.cellIndex;
    cellValues.brick.rowIndex = cell.rowIndex;
    cellValues.brick.editable = cell.isEditable;
    cellValues.selectedValues = _.cloneDeep(cell.selected);
    // sm-7310, enabling brics to normal state when doubleClicking objective bric
    if (row.bric.bricid === 28) {
      this.enableAllMasterBrics();
    }
    this.openBricModalWindow(cellValues, true);
  }
  /**
  * @description method to convert blob data to FILE object SM-11463
  * @author Siddharth Vaidya
  * @memberof WorkspaceComponent
  */
  blobToFile(blob, fileName) {
    const fileOptions = { type: blob.type };
    const file = new File([blob], fileName, fileOptions);
    return file;
  }
  /**
   * @description open bric modal popup
   * @author Alkesh Shah
   * @param {CellValues} values - cell values to use to open modal window
   * @package {boolean} isEditMode - Send this flag to true if user is editing any existing brick
   * @memberof WorkspaceComponent
   */
  async openBricModalWindow(values: CellValues, isEditMode = false) {
    if (values.brick.bricid === this.brickBaseService.brickID.MultiTarget) {
      const mtbRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.MultiTarget));
      let mtbCells;
      if (mtbRow && mtbRow.cells) {
        mtbCells = mtbRow.cells;
      }
      if (values.selectedValues?.listUpload) {
        let uploadedFile = await this.multiTargetService.downloadFile(values.selectedValues.listUpload.fileList[0].userSelectionId);
        let _file = this.blobToFile(uploadedFile.body, values.selectedValues.listUpload.fileList[0].userSelectionName)
        _file['userSelectionId'] = values.selectedValues.listUpload.fileList[0].userSelectionId;
        values.selectedValues.listUpload.fileList = [_file];
      }
      const inchargeRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Incharge));
      const cells = inchargeRow.cells;
      const currentCell = cells.find(x => x.cellIndex === values.brick.cellIndex);
      const cellIndex = inchargeRow.getOverlappingCell(currentCell.cellIndex);
      if (cellIndex !== -1) {
        let listUpload = [];
        let selectedSOTOption = "";
        if (isEditMode && mtbCells) {
          listUpload = mtbCells[values.brick.cellIndex].selected.listUpload;
          selectedSOTOption = mtbCells[values.brick.cellIndex].selected.selectedSOTOption;
        }
        if (!values.selectedValues || !values.selectedValues.selectionPeriod) {
          values.selectedValues = {
            selectionPeriod: {
              startDate: moment(cells[cellIndex].requestJSON.selectionPeriod.startDate).format('YYYY-MM-DD'),
              endDate: moment(cells[cellIndex].requestJSON.selectionPeriod.endDate).format('YYYY-MM-DD')
            },
            listUpload: listUpload,
            selectedSOTOption: selectedSOTOption
          };
        }
      } else if (values.selectedValues) {
        values.selectedValues.selectionPeriod = {
          startDate: moment(currentCell.requestJSON.selectionPeriod.startDate).format('YYYY-MM-DD'),
          endDate: moment(currentCell.requestJSON.selectionPeriod.endDate).format('YYYY-MM-DD')
        };
      } else {
        values.selectedValues = {
          selectionPeriod: {
            startDate: moment(currentCell.requestJSON.selectionPeriod.startDate).format('YYYY-MM-DD'),
            endDate: moment(currentCell.requestJSON.selectionPeriod.endDate).format('YYYY-MM-DD')
          }
        };
      }
    }
    const cellComponent = this.getComponent(values.brick.bricid);
    const modalOptions: NgbModalOptions = this.getComponentModalOptions(values.brick.bricid);
    const columnConfig: ColumnConfigs = this.stateService.getWorkspaceObject('columnConfig');
    if (this.filter.rows && this.filter.rows[values.brick.rowIndex] && this.filter.rows[values.brick.rowIndex].cells[values.brick.cellIndex]) {
      values.cellWidth = this.filter.rows[values.brick.rowIndex].cells[values.brick.cellIndex].cellWidth;
    }
    values.columnConfig = this.assignColumnConfig(columnConfig, values.brick.cellIndex, values.brick.bricid);
    const columnSummary = this.stateService.getAllColumnSummary();
    if (this.brickBaseService.floorCeilingBrics.indexOf(values.brick.bricid) >= 0) {
      values.columnSummary = SystemFlags.incorrectSolution ? null : this.filter.getColumnSummary(columnSummary, values.brick.bricid, values.brick.cellIndex) as ColumnSummary;
      values.sot = this.filter.getSOTValue(values.brick.cellIndex, values.brick.bricid);
      values.sot.mediaOnlyPaper = this.filter.checkIfColumnHasOnlyPaper(values.brick.cellIndex);
      if (values.selectedValues && !values.selectedValues.hasOwnProperty('-99')) {
        values.selectedValues.sotFloor = values.sot.sotFloor;
        values.selectedValues.sotCeiling = values.sot.sotCeiling;
        values.selectedValues.sotIncrement = values.sot.sotIncrement;
      }
    } else if (this.filter.isQuantityBric(values.brick.bricid)) {
      values.columnSummary = SystemFlags.incorrectSolution ? null : this.filter.getColumnSummary(columnSummary, values.brick.bricid, values.brick.cellIndex) as ColumnSummary;
    } else {
      values.columnSummary = columnSummary ? columnSummary[values.brick.cellIndex] : null;
    }
    // Validate SOT Brick - For Product having Floor/Ceiling Values in Budget/Volumne BRIC
    if (this.brickBaseService.brickID.SOT === values.brick.bricid && this.filter.productDetails && this.filter.productDetails.length) {
      const validations = this.filter.getSOTRestrictionDetails(values.brick);
      if (validations) {
        values.sotRestriction = validations;
      }
    }
    const columnHelper = this.stateService.getColumnHelper();
    const islocked = (columnHelper && columnHelper[values.brick.cellIndex]) ? true : false;
    const readOnlyModal = !values.brick.editable || islocked;
    values.readOnlyModal = this.isModalPopupReadonly(readOnlyModal);

    const colIndexToCheck = this.filter.checkIfCellExistsInColumn(values.brick.cellIndex,
      Number(this.brickBaseService.brickID.ProductCatalogue), true);
    if (values.brick.bricid === this.brickBaseService.brickID.PricingTag) {
      values.isOptionalInProduct = this.pcmService.isOptionalInProduct(colIndexToCheck, this.filter.productDetails,
        Number(this.brickBaseService.brickID.PricingTag), values.brick.cellIndex);
    }
    const productHelper = this.filter.getProductHelperIfProductExists(colIndexToCheck);
    values.productValidations = (productHelper && productHelper.validation) ? this.filter.getProductValidations(values.brick.cellIndex, productHelper, values.brick.bricid) : null;
    values.isEditMode = isEditMode;
    if (values.productValidations && (values.productValidations.hasOwnProperty('-99') || _.isEmpty(values.productValidations))) {
      values.productValidations = {};
    }
    if (this.filter.isQuantityBric(values.brick.bricid) && values.columnSummary && !isEditMode) {
      values.selectedValues = this.filter.getQuantityBricSelectedValue(
        values.brick.rowIndex,
        values.brick.cellIndex,
        values.brick.bricid
      );
    }
    values.reshufflingEngine = this.filter.allocationEngines[
      values.brick.cellIndex
    ]
      ? this.filter.allocationEngines[values.brick.cellIndex]
      : this.filter.allocationEngines[values.brick.cellIndex - 1];
    if (columnSummary && Object.keys(columnSummary).indexOf(String(values.brick.cellIndex)) === -1
      && Object.keys(this.filter.columnSummaryWithoutBrick).indexOf(String(values.brick.cellIndex)) !== -1) {
      this.filter.columnSummaryWithoutBrick.splice(values.brick.cellIndex, 1);
    }
    if (!this.filter.columnSummaryWithoutBrick[values.brick.cellIndex]
      || (columnSummary && columnSummary[values.brick.cellIndex].price > this.filter.columnSummaryWithoutBrick[values.brick.cellIndex].price)
      || (columnSummary && columnSummary[values.brick.cellIndex].audienceImpressions > this.filter.columnSummaryWithoutBrick[values.brick.cellIndex].audienceImpressions)) {
      this.filter.columnSummaryWithoutBrick[values.brick.cellIndex] = columnSummary[values.brick.cellIndex];
    }
    values.columnSummaryWithoutBrick = this.filter.columnSummaryWithoutBrick[values.brick.cellIndex];
    if (values.brick.bricid === this.brickBaseService.brickID.Objective) {
      this.objectiveService.objectiveModeFor = ObjectiveModeForEnum.workspace;
      if (columnHelper && columnHelper[values.brick.cellIndex] && (columnHelper[values.brick.cellIndex].userLocked || columnHelper[values.brick.cellIndex].systemLocked)) {
        values.selectedValues.disableObjective = true;
      }
      this.objectiveService.isEditMode = isEditMode;
      this.stateService.setObjectiveObject('currentObjectiveData', values);
      this.appHeaderService.changeObjectiveMode(true);
      this.filter.removeHighlighters();
      this.router.navigate(['objective']);
      return;
    }
    this.sbModalPopupService.open(cellComponent, values, modalOptions).result.then((result: CellValues) => {
      if (values.brick.bricid === this.brickBaseService.brickID.Network) {
        this.filter.addNetworkData(result);
      } else if (values.brick.bricid === this.brickBaseService.brickID.Incharge) {
        if (SystemFlags.isRangeSelectionMandatory) {
          // SM-7855 : If campaign is clone campaign and range selection is mandatory then it will update next column's dates automatically.
          const inchargeRowIndex = this.filter.getExistingRowIndex(Number(this.brickBaseService.brickID.Incharge));
          if (this.filter.rows[inchargeRowIndex].cells.length) {
            for (let index = 0; index < this.filter.rows[inchargeRowIndex].cells.length; index = index + 1) {
              const cell: Cell = this.filter.rows[inchargeRowIndex].cells[index];
              if (index > 0 && !cell.isEmpty && !cell.isHidden) {
                const diffStart = moment(this.filter.rows[inchargeRowIndex].cells[index].selected.startDate).diff(this.filter.rows[inchargeRowIndex].cells[0].selected.startDate, 'days');
                const startDate = moment(result.selectedValues.startDate).add(diffStart, 'd').toDate();
                const diffEnd = moment(this.filter.rows[inchargeRowIndex].cells[index].selected.endDate).diff(moment(this.filter.rows[inchargeRowIndex].cells[0].selected.startDate), 'days');
                const endDate = moment(result.selectedValues.startDate).add(diffEnd, 'd').toDate();
                const cellValue = {
                  startDate,
                  endDate
                };
                cell.selected = {
                  startDate,
                  endDate
                };
                cell.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Incharge, cellValue);
                cell.requestJSON = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Incharge, cellValue);
              }
            }
          }
          // SM-7855 : Once Date selection completed first time then after date selection flow will be normal(same as before)
          SystemFlags.isRangeSelectionMandatory = false;
        }
        const previousBricksDates: DateRange[] = this.workspaceService.getDatesFormAllIncharge(this.stateService.getWorkspaceFilterObj().rows);
        const data = this.workspaceService.allDatesProcessing(result.selectedValues, previousBricksDates, values.brick, this.filter.rows);
        if (data.bricksToPush && data.bricksToPush.length > 0) {
          this.filter.addMultipleRangeBric(data, result);
        } else {
          this.filter.addBrick(result, true, isEditMode);
        }
      } else {
        if (values.brick.bricid === this.brickBaseService.brickID.Volume) {
          result.selectedValues.updatedFromModal = true;
        }
        if (values.brick.bricid === this.brickBaseService.brickID.PrimaryAudience
          || values.brick.bricid === this.brickBaseService.brickID.AllAudience) {
          for (const row of this.filter.rows) {
            if (row.bric.bricid === this.brickBaseService.brickID.Volume) {
              for (const cell of row.cells) {
                if ((values.brick.cellIndex === cell.cellIndex || cell.cellIndex < values.brick.cellIndex + values.cellWidth) && cell.selected) {
                  cell.selected.updatedFromModal = true;
                  cell.selected.updatedFromAudience = true;
                  if (cell.selected.actualFtgTarget) {
                    cell.selected.impressions = _.clone(cell.selected.actualFtgTarget);
                    cell.requestJSON.selectionAudienceReduction.impressions = _.clone(cell.selected.actualFtgTarget);
                    // SM-8617
                    // cell.selected.actualFtgAllocated = 0;
                    // cell.requestJSON.selectionAudienceReduction.actualFtgAllocated = 0;
                  }
                }
              }
            }
          }
        }
        this.filter.addBrick(result, true, isEditMode);

        if (values.brick.bricid === this.brickBaseService.brickID.Media) {
          // When media bric value is Paper, Need to update floor ceiling values for volume/budget bric
          this.filter.updateFloorAndCeilingValues(result.brick.cellIndex, result.selectedValues.selectedMedia === '0');
        }

        if (values.brick.bricid === this.brickBaseService.brickID.Media) {
          if (GLOBAL.localSolverEnabled) { // This means Local Solver is enabled
            // SM-6264
            // Always need to reset to allocationEngine when media type change

            if (this.filter.rows[result.brick.rowIndex] && this.filter.rows[result.brick.rowIndex].cells) {
              for (let a = 0; a < this.filter.rows[result.brick.rowIndex].cells[result.brick.cellIndex].cellWidth; a++) {
                this.filter.allocationEngines[result.brick.cellIndex + a] = result.selectedValues.selectedMediaObject.defaultAllocationEngine || this.filter.initialConfig.uiControl.defaultAllocationEngine;
              }
            } else {
              this.filter.allocationEngines[result.brick.cellIndex] = result.selectedValues.selectedMediaObject.defaultAllocationEngine || this.filter.initialConfig.uiControl.defaultAllocationEngine;
            }
          }
        }
      }
      setTimeout(() => {
        this.proximityAvailable();
      }, 1000);
    }, () => {
      this.filter.isMandatoryBricksAvailable();
    });
  }

  /**
   * @description This event will be triggered whenever user will click on any brick
   * @author Vijay Sutaria
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  onBrickClick(cell: Cell) {
    if (this.allowExplode) {
      this.allowExplode = this.filter.explodeCell(cell);
    } else if (this.allowCustomDelete) {
      if (!cell.isEmpty && !cell.isLocked && !cell.isMandatoryForProduct && cell.isEditable) {
        cell.markSelected = !cell.markSelected;
      }
      this.proximityAvailable();
    } else if (this.allowSplit) {
      this.splitCampaign(cell);
    }
  }

  /**
   * @description Handles multiple events on footer buttons like explode, split & multidelete
   * @author Vijay Sutaria
   * @param {string} buttonType
   * @memberof WorkspaceComponent
   */
  toggleButtons(buttonType: string) {
    this.filter.removeMarkSelected();
    switch (buttonType) {
      case 'explode':
        this.allowExplode = !this.allowExplode;
        this.allowSplit = false;
        this.allowCustomDelete = false;
        break;
      case 'split':
        this.allowSplit = !this.allowSplit;
        this.allowExplode = false;
        this.allowCustomDelete = false;
        break;
      case 'multiDelete':
        this.allowSplit = false;
        this.allowExplode = false;
        this.allowCustomDelete = !this.allowCustomDelete;
    }
  }

  ngOnDestroy() {
    this.columnSummarySubscription.unsubscribe();
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }

  /**
   * @description checkAvaibility Dashboard
   * @author Kishan Patel
   * @memberof WorkspaceComponent
   */
  checkAvaibility() {
    if (this.workspaceActionButtonService.checkAvaibility()) {
      this.router.navigate(['/result']);
    }
  }

  /**
   * @description open Advance Availability modal popup
    * @author Kishan Patel
   */
  openAdvanceAvailability() {
    this.showExportMenu = false;
    if (this.filter.isMandatoryBricksAvailable()) {
      this.workspaceActionButtonService.openAdvanceAvailability();
    } else {
      this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
    }
  }

  /**
   * @description method to export proxomity report if proximity brick is available
   */
  downloadProximityReport() {
    this.showExportMenu = false;
    if (this.filter.isMandatoryBricksAvailable() && this.isProximityExportAllowed) {
      this.workspaceActionButtonService.downloadProximityReport();
    } else {
      this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
    }
  }

  /**
  * @description download KML File
  * @author Kishan Patel
  * @memberof workspaceActionButtonService
  */
  downloadKMLFile() {
    if (this.filter.isMandatoryBricksAvailable() && !SystemFlags.incorrectSolution) {
      this.workspaceActionButtonService.downloadKMLFile();
    } else {
      if (!this.filter.isMandatoryBricksAvailable()) {
        this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
      } else if (SystemFlags.incorrectSolution) {
        this.logHelperService.logError(this.userBundle['workspace.error.invalidBricks']);
      }
    }
  }

  /**
   * @description redirect to result page
   * @author Kishan Patel
   * @memberof WorkspaceComponent
   */
  redirectToResultPage() {
    if (!SystemFlags.incorrectSolution && this.filter.isMandatoryBricksAvailable() && this.filter.toleranceLimit.cellAddress.length === 0) {
      this.router.navigate(['/result']);
    } else {
      if (!this.filter.isMandatoryBricksAvailable()) {
        this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
      } else if (SystemFlags.incorrectSolution) {
        this.logHelperService.logError(this.userBundle['sot.error.steps'] || 'Workspace is invalid!');
      }
    }
  }

  /**
   * @description Toggles all lock
   * @author Amit Mahida
   * @memberof WorkspaceComponent
   */
  toggleAllLocking() {
    // Store current item to History Stack
    this.historyStackService.pushInHistoryStack(new HistoryStackData(this.filter));
    this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
    const columnHelper = this.stateService.getColumnHelper();
    this.historyStackService.pushColumnHelperInHistoryStack(columnHelper);
    this.filter.lockAllButton = !this.filter.lockAllButton;
    this.filter.toggleAllColumnsLock(this.filter.lockAllButton);
    for (let index = 0; index < this.filter.rows[0].cells.length; index++) {
      if (this.filter.lockAllButton && !columnHelper[index].systemLocked) {
        columnHelper[index] = {
          systemLocked: false,
          userLocked: true,
          shown: true,
          allocationEngine: ''
        };
      } else {
        if (columnHelper[index] && !columnHelper[index].systemLocked) {
          delete columnHelper[index];
        }
      }
    }
    this.stateService.setColumnHelper(columnHelper);
    this.filter.updateWorkspaceOnLock();
    this.filter.populateReshufflingColumns(false, null);
  }

  /**
   * @description Populates watermark text on workspace
   * @author Amit Mahida
   * @memberof WorkspaceComponent
   */
  populateWaterMark() {
    const campaignDetail = this.stateService.getCampaign();
    if (campaignDetail && SystemFlags.isLoadedCampaign) {
      this.waterMark = {
        campaignReference: campaignDetail.campaignReference,
        agency: campaignDetail.agency ? campaignDetail.agency.agencyName : '',
        advertiser: campaignDetail.advertiser && campaignDetail.advertiser.advertiserName,
      };
    } else {
      this.waterMark = {
        advertiser: campaignDetail.advertiser && campaignDetail.advertiser.advertiserName,
      };
    }
  }

  /**
   * @description Toggles columns lock
   * @author Amit Mahida
   * @param {number} index
   * @memberof WorkspaceComponent
   */
  toggleLock(index: number) {
    // Store current item to History Stack
    this.historyStackService.pushInHistoryStack(new HistoryStackData(this.filter));
    this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
    this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
    this.filter.lockColumn(index);
    this.filter.lockAllButton = this.filter.isAllColumnsLocked();
    this.filter.updateWorkspaceOnLock();
    this.filter.populateReshufflingColumns(false, null);
    this.filter.rows = _.cloneDeep(this.filter.rows);
    this.stateService.setWorkspaceObject('filter', this.stateService.clone(this.filter));
  }

  /**
    * @description Disables master brick on left sidebar
    * @author Amit Mahida
    * @param {number} cellIndex
    * @memberof WorkspaceComponent
    */
  disableMasterBrics = (cellIndex: number) => {
    if (this.filter.productDetails.length && cellIndex >= 0) {
      let productDetail = this.filter.getProductHelperIfProductExists(cellIndex);
      const productCatalogueBrickId = Number(this.brickBaseService.brickID.ProductCatalogue);
      const overlappingProductIndex = this.filter.checkIfCellExistsInColumn(cellIndex, productCatalogueBrickId, true);
      if (overlappingProductIndex >= 0) {
        const productRow = this.filter.getRowByBrickId(productCatalogueBrickId);
        const productCellRequestJSON = productRow.cells[cellIndex].requestJSON || productRow.cells[overlappingProductIndex].requestJSON;
        const productCellReqJSON = productCellRequestJSON[this.brickBaseService.brickReqJsonText[productCatalogueBrickId]];
        const isColumnExploded = productCellReqJSON.explodedColumn && productCellReqJSON.explodedColumn[overlappingProductIndex];
        if (isColumnExploded && overlappingProductIndex !== -1 && overlappingProductIndex !== cellIndex) {
          cellIndex = overlappingProductIndex;
        }
        productDetail = this.filter.getProductHelperIfProductExists(cellIndex);
        if (productDetail) {
          this.masterData.forEach((category) => {
            category.brics.forEach((bric) => {
              bric.disabled = productDetail.optional[cellIndex]
                && productDetail.optional[cellIndex].indexOf(bric.bricid) > -1
                ? false : true;
            });
          });
        }
      }
    }
  }

  /*
    * @description Handles remove event of row/cell
    * @author Amit Mahida
    * @param {DndDropEvent} event
    * @memberof WorkspaceComponent
    */
  onRemoveBrick(event: DndDropEvent) {
    this.historyStackService.pushInHistoryStack(new HistoryStackData(this.filter));
    this.historyStackService.pushColumnHelperInHistoryStack(this.stateService.getColumnHelper());
    this.historyStackService.pushColumnLineNumberInHistoryStack(this.stateService.getWorkspaceObject('columnLineNumber'));
    switch (event.type) {
      case 'row':
        this.filter.removeRow(event.data.index);
        break;
      case 'cell':
        if (this.filter.getMarkSelectedCount() || this.allowCustomDelete) {
          this.filter.removeMultipleBric();
        } else {
          this.filter.removeBric(event.data.rowIndex, event.data.cellIndex);
        }
        break;

      default:
        break;
    }
    this.allowCustomDelete = false;
    this.enableAllMasterBrics();
    this.proximityAvailable();
    this.showRemoveBar = false;
    this.filter.displayBricksInLeftPanel();
    // sm-8344
    if (SystemFlags.isFillerCampaign) {
      this.handleBricsForFillerCampaign();
    }
  }

  /**
  * @description Enables master brick on left sidebar
  * @author Amit Mahida
  * @memberof WorkspaceComponent
  */
  enableAllMasterBrics = () => {
    this.masterData.forEach((category) => {
      category.brics.forEach((bric) => {
        bric.disabled = false;
      });
    });
  }

  /**
   * @description Enable product mode
   * @author Amit Mahida
   * @memberof WorkspaceComponent
   */
  enableProductMode() {
    this.appHeaderService.changePCMMode(true);
    this.router.navigate(['pcm']);
  }

  /**
  * @description checks if locking is enabled
  * @author Amit Mahida
  * @returns
  * @memberof WorkspaceComponent
  */
  checkIfColumnLockingEnabled() {
    return !SystemFlags.incorrectSolution && !SystemFlags.readOnlyWorkspace;
  }

  /**
   * @description checks if change in allocation engine is allowed or not
   * added for SM-10833
   * @returns boolean value
   */
  checkIfAllocationEngineCanChange() {
    return SystemFlags.readOnlyWorkspace;
  }

  /**
   * @description toggles visibility of past columns
   * @author Amit Mahida
   * @returns
   * @memberof WorkspaceComponent
   */
  toggleVisibilityOfPastColumns() {
    if (SystemFlags.isLoadedCampaign && !SystemFlags.splitable) {
      const columnHelper = this.stateService.getColumnHelper();

      const pastColumns: string[] = Object.keys(columnHelper).filter(colIndex => columnHelper[colIndex].systemLocked);
      if (this.filter.getMaxCellIndex() === pastColumns.length - 1) {
        this.logHelperService.logError(this.userBundle['workspace.button.eye.validation.onlyPastColumns'] || 'Show/Hide feature is not available if there are only past columns on workspace!');
        return;
      }

      let allowHideCampaign = true;
      if (!_.isEmpty(columnHelper)) {
        this.filter.rows.forEach((row) => {
          row.cells.forEach((cell) => {
            if (cell.cellWidth > 1
              && columnHelper[cell.cellIndex]
              && columnHelper[cell.cellIndex].systemLocked
              && (!columnHelper[cell.cellIndex + cell.cellWidth - 1] || !columnHelper[cell.cellIndex + cell.cellWidth - 1].systemLocked)
            ) {
              // non-editable column is stretched
              allowHideCampaign = false;
            }
          }, this);
        }, this);
      }

      if (allowHideCampaign && pastColumns.length) {
        this.filter.isPastColumnHidden = !this.filter.isPastColumnHidden;
        this.filter.manageVisibilityOfPastColumns(this.filter.isPastColumnHidden, columnHelper, this.columnSummarys);

        this.filter.rows.forEach((row: Row) => {
          row.setIsHidden(this.filter.isPastColumnHidden, columnHelper);
        });
      } else {
        this.logHelperService.logError(this.userBundle['workspace.button.eye.validation.strechedPastColumns'] || 'Show/Hide feature is not available in case of streched past columns!');
        return;
      }
    }
  }

  /**
   * @description it will decide to allow open bric modal popup
   * @author Nishit Parekh
   * @param {Cell} cell
   * @param {number} bricId
   * @returns boolean value
   * @memberof WorkspaceComponent
   */
  checkIfBricallowedToOpen(cell: Cell, bricId: number) {
    let allowed = true;

    // Volume of any Product is allowed to open
    if ((cell.isMandatoryForProduct && this.brickBaseService.PCM_EDITABLE_BRICS.indexOf(bricId) === -1) || cell.isEmpty) {
      allowed = false;
    }
    // if we try to open Incharge bric and it has pattern birc below it, it should not allow to open
    if (bricId === this.brickBaseService.brickID.Incharge && this.filter.rows[cell.rowIndex + 1] &&
      this.filter.rows[cell.rowIndex + 1].bric.bricid === this.brickBaseService.brickID.Pattern) {
      const resized = this.filter.rows[cell.rowIndex].getOverlappingCell(cell.cellIndex);
      let startIndex = null;
      if (resized === -1) {
        startIndex = cell.cellIndex;
      } else {
        startIndex = resized;
      }
      // case when incharge bric is stretched then check for pattern bric below each resixed cell
      for (let index = startIndex; index < startIndex + cell.cellWidth; index++) {
        const patternBric = this.filter.rows[cell.rowIndex + 1].cells[index];
        if (patternBric.isEmpty && !patternBric.isHidden) {
          allowed = true;
        } else {
          this.logHelperService.logError(this.userBundle['workspace.error.pattern.noEditable']);
          allowed = false;
          break;
        }
      }
    }
    // if we try to open pattern and it is not valid than it should not be open
    if (bricId === this.brickBaseService.brickID.Pattern && !cell.isValid) {
      allowed = false;
      this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
    }
    return allowed;
  }

  ghostCellHighlight(isHighlight = false) {
    this.highlightGhost = isHighlight;
  }

  /**
   * @description Handles drag brick over cell
   * @author Amit Mahida
   * @param {DragEvent} $event
   * @param {HTMLDivElement} cellElement
   * @param {number} bricid
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  dragOverCell($event: DragEvent, cellElement: HTMLDivElement, bricid: number, cell: Cell) {
    cell.highlight($event, this.draggedBricHighlight, cellElement, bricid);
    this.setExpandDirection(cell);
    this.proximityAvailable();
  }

  /**
   * @description Decides which conumn config for which index
   * @author Nishit Parekh, Amit Mahida
   * @param {ColumnConfigs} columnConfig
   * @param {number} cellIndex
   * @param {number} brickId
   * @returns {ColumnConfig}
   * @memberof WorkspaceComponent
   */
  assignColumnConfig(columnConfig: ColumnConfigs, cellIndex: number, brickId: number): ColumnConfig {
    const brickAllowedForLastConfig = [this.brickBaseService.brickID.Location, this.brickBaseService.brickID.Tag, this.brickBaseService.brickID.Format, this.brickBaseService.brickID.PricingTag];
    let columnConfigAtIndex = null;

    // if columnConfig available use it from that index
    if (Object.keys(columnConfig).length && columnConfig[cellIndex]) {
      columnConfigAtIndex = columnConfig[cellIndex];
      // if columnConfig unavailable at that index, check for previous index,
      // TODO:VJ: Need to analyse why brick ids are needed in below condition, infact last column config should be used
      // wherever its required when not available for current index, in AngularJS version no condition is there
    } else if (Object.keys(columnConfig).length && brickAllowedForLastConfig.indexOf(brickId) > -1) {
      columnConfigAtIndex = columnConfig[Object.keys(columnConfig).length - 1];
    }
    return columnConfigAtIndex;
  }
  /**
   * @description Hide Volumne Budget and Product Catagloue brick for Filler Campaign
   * @author Shreni
   * @date 2020-04-01
   * @memberof WorkspaceComponent
   */
  handleBricsForFillerCampaign() {
    // Disable Volume, Budget, Product BRICS in Case of Filler Campaign//
    this.masterData.forEach((category: Category) => {
      category.brics.forEach((brick: Brick) => {
        if ([
          this.brickBaseService.brickID.Volume,
          this.brickBaseService.brickID.ProductCatalogue,
          this.brickBaseService.brickID.Budget
        ].indexOf(brick.bricid) !== -1) {
          brick.hide = true;
        }
      });
      const hiddenBrics = category.brics.filter(bric => bric.hide === true);
      if (hiddenBrics.length === category.brics.length) {
        category.hide = true;
      }
    });
  }

  /**
   * Method to handle change of allocation engine for current column
   * SM-7987
   * @author Vijay Sutaria
   * @param colIndex colIndex for which allocation engine to be toggle
   * @memberof WorkspaceComponent
   */
  onAllocationEngineClick(colIndex: number) {
    // SM-10833 Allow to user to change the LS/VIOOH to GS and GS to LS/VIOOH.
    const error = this.filter.CheckIfMultitragetBrickIsAllowed(null, null, colIndex);
    const multiTargetBrickExist = this.filter.rows.find(e => e.bric.bricid === this.brickBaseService.brickID.MultiTarget && !e.cells[colIndex].isEmpty);
    if (multiTargetBrickExist && error && !error?.isValid) {
      return this.logHelperService.logError(error.note);
    }
    let paperDefaultAllocationEngine = this.uiControl.defaultAllocationEngine;
    const mediaTypes = this.dataShareService.getInitialConfigByKey('mediaType');

    let isVioohAvailable = -1 < _.findIndex(mediaTypes, function (mediaType) {
      return mediaType.defaultAllocationEngine && mediaType.defaultAllocationEngine == GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH;
    });

    let allocationEngines = paperDefaultAllocationEngine;

    if (this.filter.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS && isVioohAvailable) {
      allocationEngines = GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH;
    } else if (this.filter.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS
      || this.filter.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH) {
      allocationEngines = GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO;
    } else if (paperDefaultAllocationEngine !== GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_GISGO) {
      allocationEngines = paperDefaultAllocationEngine;
    }
    this.filter.allocationEngines[colIndex] = allocationEngines;
    if (this.filter.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.filter.allocationEngines[colIndex] === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH) {
      // User is not allowed to go back to Gisgo once they have selected LS
      // this.historyStackService.resetHistoryStack();
    }
    this.filter.processBrics();
  }

  /**
   * @description Handles reshuffling
   * @author Amit Mahida
   * @param {number} colIndex
   * @memberof WorkspaceComponent
   */
  onReshufflingClick(colIndex: number) {
    if (!GLOBAL.localSolverEnabled) {
      this.reshuffleService.processReshuffle(colIndex, false).subscribe((res) => {
        if (res.status === 'KO') {
          this.logHelperService.logError(res.message);
        } else {
          this.openReshuffleDetail(colIndex, res.data);
        }
      });
    } else if (GLOBAL.localSolverEnabled) {
      this.reshuffleService.getLSReshuffleCampaigns(colIndex).subscribe((res) => {
        if (res.status === 'KO') {
          this.logHelperService.logError(res.message);
        } else {
          this.openLSReshuffleDetail(colIndex, res);
        }
      });
    }
  }

  openLSReshuffleDetail(columnIndex: number, res: any) {
    const modalOptions: NgbModalOptions = {
      windowClass: 'size-xl',
      backdrop: 'static'
    };
    const resolveObject = {
      columnIndex,
      ...res.data && { data: res.data },
      selectedProducts: this.getSelectedProductFormat(columnIndex),
    };
    //SM-10985
    if (res.message !== "") {
      resolveObject['message'] = res.message;
    }
    const tokens = this.dataShareService.getInitialConfigByKey('userData');
    let isReshuffleScopeAvailable = false;
    if (tokens.tokens.indexOf('RESHUFFLE_SCOPE_LIMIT') > -1) {
      isReshuffleScopeAvailable = true;
    }
    if (this.uiControl.reshuffleScopingEnabled && isReshuffleScopeAvailable) {
      let excludeLabelItem;
      let reshuffleScopeIndex = -1;
      if (res.data.reshuffleScopeLookUp && res.data.reshuffleScopeLookUp.length) {
        res.data.reshuffleScopeLookUp.forEach((element, index) => {
          if (element.selectionId === 38 && element.lookupType === 38) {
            excludeLabelItem = {
              id: -1,
              name: element.displayName,
              inUse: true
            };
            reshuffleScopeIndex = index;
          } else {
            return element.lookupType = 2;
          }
        });
      }
      if (reshuffleScopeIndex != -1) {
        res.data.reshuffleScopeLookUp.splice(reshuffleScopeIndex, 1)
      }
      resolveObject['reshuffleCandidates'] = res.data.reshuffleCandidates;
      resolveObject['reshuffleScopeLookUp'] = res.data.reshuffleScopeLookUp;
      resolveObject['reshuffleScopeLookUpData'] = res.data.reshuffleScopeLookUpData;
      resolveObject['reshuffleScopeLimitCriteria'] = res.data.reshuffleScopeLimitCriteria['38'];
      resolveObject['dummySelectOption'] = excludeLabelItem;
    }
    this.sbModalPopupService.open(ReshuffleDetailLSComponent, resolveObject, modalOptions).result.then((result) => {
      const finalData = [];
      let rowData;
      let reshuffleScopeUserSelection;
      this.uiControl.reshuffleScopingEnabled && isReshuffleScopeAvailable ? rowData = result.rowData : rowData = result;
      if (this.uiControl.reshuffleScopingEnabled && isReshuffleScopeAvailable) {
        rowData = result.rowData
        reshuffleScopeUserSelection = result.reshuffleScopeUserSelection;
      } else {
        rowData = result;
      }
      rowData.forEach((element) => {
        finalData.push({
          columnIndex: element.columnIndex,
          campaignId: element.campaignId,
          locked: element.lockedEditable ? element.lockedAtUI : (element.locked || element.lockedAtUI),
          filler: element.filler,
          reshuffleColumns: element.reshuffleColumns
        });
      });

      this.lsProcessCompleted = false;
      this.reshuffleService.executeReshuffle(columnIndex, finalData, reshuffleScopeUserSelection).subscribe((res) => {
        this.lsProcessCompleted = true;
        this.kpiData = null;
        this.showLsMessages = false;
        if (res.status === 'KO') {
          this.logHelperService.logError(res.message);
        } else {
          const resolveResultObject = {
            columnIndex,
            data: res.data
          };
          this.sbModalPopupService.open(ReshuffleDetailLSResultComponent, resolveResultObject, modalOptions).result.then(
            (status) => {
              switch (status) {
                case 'save':
                  this.saveOrCancelReshuffle(true);
                  break;
                case 'cancel':
                  this.saveOrCancelReshuffle(false);
                  break;
              }
            }, (reason) => {
              if (reason === 'timeout') {
                // handling reshuffle timedout
                const timeOutModalOptions: NgbModalOptions = {
                  windowClass: 'size-sm',
                  backdrop: 'static'
                };
                const timeOutResolveObject = {
                  message: this.userBundle['reshuffle.timeout.message'] || 'Your reshuffle request has timed out. If you wish to complete this action please launch the reshuffle process again',
                };
                this.sbModalPopupService.open(ReshuffleConfirmComponent, timeOutResolveObject, timeOutModalOptions).result.then(() => {
                  this.saveOrCancelReshuffle(false);
                }, () => {
                  this.saveOrCancelReshuffle(false);
                });
              }
            });
        }
      });

      setTimeout(() => {
        this.fetchLSReshuffleKPIData(1);
      }, 1000);

    }, () => {
      this.saveOrCancelReshuffle(false);
    });
  }

  /**
   * This methos will get name of selected product format from Format brick and Objective brick
   * @param columnIndex column Index of column structure
   * @returns string
   */
  private getSelectedProductFormat(columnIndex: number) {
    const formateBricRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Format));
    const objectiveBricRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Objective));
    let selectedProductFormats = '';
    if (objectiveBricRow && objectiveBricRow.cells && objectiveBricRow.cells[columnIndex].selected && objectiveBricRow.cells[columnIndex].selected.objectives) {
      const formatRow: Row = objectiveBricRow.cells[columnIndex].selected.objectives.find((row: Row) => {
        return row['bric'].bricid === Number(this.brickBaseService.brickID.Format);
      });
      selectedProductFormats = this.joinSelectedProductFormat(columnIndex, formatRow, selectedProductFormats);
    }
    if (formateBricRow) {
      selectedProductFormats = this.joinSelectedProductFormat(columnIndex, formateBricRow, selectedProductFormats);
    }
    return selectedProductFormats;
  }

  /**
   * This method will join the name of each selected product format
   * @param columnIndex column Index of column structure
   * @param formateBricRow Format brick row
   * @param selectedProductFormats string
   * @returns string
   */
  private joinSelectedProductFormat(columnIndex: number, formateBricRow, selectedProductFormats) {
    if (formateBricRow && formateBricRow.cells[columnIndex] && formateBricRow.cells[columnIndex].selected) {
      Object.keys(formateBricRow.cells[columnIndex].selected).forEach((key) => {
        const selectedFormats = _.map(formateBricRow.cells[columnIndex].selected[key], 'name').filter(Boolean).join(', ');
        let selectedProductFormatsLocal = selectedProductFormats;
        if (selectedFormats && selectedProductFormats) {
          selectedProductFormatsLocal = `${selectedFormats}, ${selectedProductFormats}`;
        } else if (selectedFormats) {
          selectedProductFormatsLocal = selectedFormats;
        }
        selectedProductFormats = selectedProductFormatsLocal;
      });
    }
    return selectedProductFormats;
  }

  /**
   * This method will rotate messages on the screen during reshuffling request
   * @param index index of message from array
   * @returns nothing
   */
  rotateLSMessages(index = 0) {
    if (this.rotateMessageTimeout) {
      clearTimeout(this.rotateMessageTimeout);
    }

    if (this.showMessageTimeout) {
      clearTimeout(this.showMessageTimeout);
    }

    if (this.lsProcessCompleted) {
      return;
    }

    index = this.uiControl.reshuffleLoadingMessages[index] ? index : 0;
    this.showLsMessages = true;
    this.reshuffleMessage = this.uiControl.reshuffleLoadingMessages[index];

    this.rotateMessageTimeout = setTimeout(() => {
      this.showLsMessages = false;
      index += 1;

      this.showMessageTimeout = setTimeout(() => {
        this.rotateLSMessages(index);
      }, 500);
    }, 3000);

  }

  /**
   * @description Fetch reshuffling KPI data, This request should be sent to backend after 500ms or 1sec of process reshuffle request.
   *              This method will try to fetch data 4 times.
   * @author Vijay
   * @param {number} tryNumber
   * @memberof WorkspaceComponent
   */
  fetchLSReshuffleKPIData(tryNumber: number) {
    // Fetch KPI details of current Reshuffling process
    // Try for 5 times , until response is not coming for this request
    this.kpiData = null;
    if (this.uiControl.reshuffleLoadingMessages) {
      this.rotateLSMessages();
    }

    if (tryNumber < 10) {
      this.reshuffleService.fetchLSReshuffleKPIData().subscribe((resKPI) => {
        if (resKPI && resKPI.status && resKPI.status.toLowerCase() === 'ok') {
          if (resKPI.data) {
            this.kpiData = resKPI.data;
          } else {
            setTimeout(() => {
              this.fetchLSReshuffleKPIData(tryNumber + 1);
            }, 2000);
          }
        } else {
          setTimeout(() => {
            this.fetchLSReshuffleKPIData(tryNumber + 1);
          }, 2000);
        }
      });
    }
  }

  /**
   * Method to save or reject the reshuffling
   * @param save Save action or cancel action
   */
  saveOrCancelReshuffle(save: boolean) {
    this.reshuffleService.saveOrCancelReshuffle(save).subscribe((res) => {
      if (res && res.status && res.status.toLowerCase() === 'ok') {
        if ((this.filter.initialConfig.uiControl.showNewBookingLineWarningOnReshuffle || this.filter.initialConfig.uiControl.showNewDiscountLineWarningOnReshuffle) && res['isBookingLinesUpdated']) {
          this.sbModalPopupService.open(ConfirmationModalComponent,
            {
              SelectedTitle: this.filter.initialConfig.userBundle.reshuffle.newLine.title || 'Information',
              confirmation: true,
              SelectedValue: this.filter.initialConfig.userBundle.reshuffle.newLine.text || 'A new line has been added on the commercial page.',
              color: 'firebrick'
            });
        }
        if (res.data && save) {
          this.logHelperService.logInfo(this.userBundle['reshuffle.text.success'] || 'Reshuffle completed successfully');
          this.dataShareService.finalPrice = res.data.campaignSummary.map(summary => summary.finalPrice);
          // there is a usecase where backend will not send workspace res, so keep same workspace
          this.filter.processBricksCallback(res);
        } else if (!save) {
          this.logHelperService.logInfo(res.message);
          this.filter.processBrics();
        }
      } else {
        this.logHelperService.logInfo(res.message || this.userBundle['reshuffle.text.error'] || 'Reshuffle failed');
      }
    }, (res) => {
      if (res.error.status === 'KO') {
        this.logHelperService.logError(res.error.message);
      }
    });
  }

  openReshuffleDetail(columnIndex: number, data: any) {
    const modalOptions: NgbModalOptions = {
      windowClass: 'size-md',
      backdrop: 'static'
    };
    const resolveObject = {
      columnIndex,
      data
    };
    this.sbModalPopupService.open(ReshuffleProcessComponent, resolveObject, modalOptions).result.then(() => {
      this.onReshufflingOk(columnIndex);
    }, (msg) => {
      this.onReshufflingCancel(msg);
    });
  }

  onReshufflingOk(columnIndex: number) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static'
    };
    const resolveObject = {
      message: this.userBundle['reshuffle.confirm.message']
    };
    this.sbModalPopupService.open(ReshuffleConfirmComponent, resolveObject, modalOptions).result.then(() => {
      this.reshuffleService.processReshuffle(columnIndex, true).subscribe((res) => {
        if (res.status === 'KO') {
          this.logHelperService.logError(res.message);
        } else {
          this.filter.processBrics();
          this.logHelperService.logSuccess(this.userBundle['reshuffle.info.processcomplete']);
        }
      });
    }, (msg) => {
      this.onReshufflingCancel(msg);
    });
  }

  onReshufflingCancel(msg: string) {
    if (msg === 'cancelclick') {
      this.reshuffleService.processReshuffle(-1, false, true).subscribe((res) => {
        if (res.status === 'KO') {
          this.logHelperService.logError(res.message);
        } else {
          this.filter.processBrics();
        }
      });
    } else if (msg === 'timeout') {
      this.onReshufflingTimeout();
    } else {
      // show error msg
      this.logHelperService.logError(msg);
    }
  }

  onReshufflingTimeout() {
    const modalOptions: NgbModalOptions = {
      windowClass: 'size-sm',
      backdrop: 'static'
    };
    const resolveObject = {
      message: this.userBundle['reshuffle.timeout.message'] || 'Your reshuffle request has timed out. If you wish to complete this action please launch the reshuffle process again',
    };
    this.sbModalPopupService.open(ReshuffleConfirmComponent, resolveObject, modalOptions).result.then(() => {
      this.filter.processBrics();
    }, () => {
      this.filter.processBrics();
    });
  }
  //#endregion - Reshuffle

  trackByIndex(index, item) {
    return index;
  }

  /**
   * When there is system locked then user locked should be false
   * @param columnHelper
   */
  handleUserLocked(columnHelper) {
    if (typeof columnHelper === 'object') {
      for (const property in columnHelper) {
        if (columnHelper[property].systemLocked) {
          columnHelper[property].userLocked = false;
        }
      }
    }
  }

}
