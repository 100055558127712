export class ColumnSummaryTooltip {
  aInchargeSummary: object = {};
  bRatingSummary: object = {};
  cImpressionsSummary: object = {};
  dBudgetSummary: object = {};
  totalFrames: Object = {};
  eFrameSummary: object = {};
  fAverageQocSummary: object = {};
  isHidden = false;
}
