import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SmartSearchService } from './smart-search.service';
import { DataShareService, LogHelperService } from '../../core/services/index';
import { SearchService } from '../search.service';
import { TableColumns } from './smart-search-column';
import { GridOptions, ColDef } from 'ag-grid-community';
import { SystemFlags, UiControl, UserModel } from '../../models';
import { GoogleAnalyticsEvents, GoogleAnalyticsEventCategory } from '../../GoogleAnalytics/GoogleAnalyticsEvent';
import { Subscription } from 'rxjs';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { AgCloneButtonComponent } from './ag-grid-support/ag-clone-button/ag-clone-button.component';
import * as moment from 'moment';
import { DateFormatPipe } from '../../core/pipes/date-format.pipe';
import { LocaleData } from '../../core/utils/LocaleData';
import * as _ from 'lodash';

enum SearchTypeSelction {
  searches = 0,
  campaigns = 1,
  requestOptions = 2,
  apiSearches = 3
}

@Component({
  selector: 'app-smart-search',
  templateUrl: './smart-search.component.html',
  styleUrls: ['./smart-search.component.css']
})
export class SmartSearchComponent implements OnInit, OnDestroy {
  noRowsTemplate = '';
  dateFormatPipe: DateFormatPipe;
  pageSize = 10;
  pageSizeOptions = [10, 15, 25, 50, 100];
  lastPage = 0;
  campaignsData = [];
  isLastPageSelected = false;
  cursor = null;

  /**
   * ui control property from initial config
   * @type {UiControl}
   * @memberof SmartSearchComponent
   */
  public uiControl: UiControl;
  /**
   * user bundle from initial config
   * @memberof SmartSearchComponent
   */
  public userBundle: any = null;
  /**
   * User model object
   * @type {UserModel}
   * @memberof SmartSearchComponent
   */
  public userModel: UserModel = null;
  /**
   * search tab selection type
   * @type {SearchTypeSelction}
   * @memberof SmartSearchComponent
   */
  public typeSelection: SearchTypeSelction = SearchTypeSelction.campaigns;
  /**
   * Grid Options
   * @type {GridOptions}
   * @memberof SmartSearchComponent
   */
  public gridOptions: GridOptions;
  /**
   * Grid column definations
   * @type {ColDef[]}
   * @memberof SmartSearchComponent
   */
  public columnDefs: ColDef[];

  public currentPage = 1;
  public totalRec = 0;

  subscriptions: Subscription[] = [];

  context: any;
  frameworkComponents = {
    agCloneButtonComponent: AgCloneButtonComponent
  };

  /**
   * Creates an instance of SmartSearchComponent.
   * @param {DataShareService} dataShareService
   * @param {SmartSearchService} smartSearchService
   * @param {SearchService} searchService
   * @param {LogHelperService} logHelperService
   * @memberof SmartSearchComponent
   */
  constructor(
    private dataShareService: DataShareService,
    private smartSearchService: SmartSearchService,
    private searchService: SearchService,
    private logHelperService: LogHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private appHeaderService: AppHeaderService
  ) {
    this.context = { componentParent: this };
    this.dateFormatPipe = new DateFormatPipe();
  }

  /**
   * prepare column def for grid
   * @memberof SmartSearchComponent
   */
  setColumnDefs(): void {
    const colDefs: ColDef[] = [];
    const cols = new TableColumns(this.userBundle, this.uiControl);
    cols.commonColumns.forEach((element) => {
      colDefs.push(element);
      if (element.field === 'requestBookingDroppingDate' && this.typeSelection !== SearchTypeSelction.requestOptions) {
        colDefs[colDefs.length - 1].hide = true;
      } else {
        colDefs[colDefs.length - 1].hide = !this.isColumnVisible(element.field);
      }
    });
    if (this.uiControl.allowCampaignClone) {
      colDefs.push(this.getCloneCell());
    }
    this.columnDefs = colDefs;
  }

  /**
   * to get the clone button column configuration
   * @returns {ColDef}
   * @memberof SmartSearchComponent
   */
  private getCloneCell() {
    return {
      headerName: 'Action', width: 100, editable: false, filter: false, colId: 'clone', sortable: false,
      cellRenderer: 'agCloneButtonComponent'
    };
  }

  /**
   * check column visible or not
   * @param {*} colName
   * @returns {boolean}
   * @memberof SmartSearchComponent
   */
  isColumnVisible(colName): boolean {
    return this.uiControl.searchTableColumns.indexOf(colName) > -1;
  }

  ngOnInit() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.userModel = this.dataShareService.getUserModel();
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.noRowsTemplate = this.userBundle['search.noRows'] ? `<span>${this.userBundle['search.noRows']}</span>` : 'No Rows To Show';
    this.setPerPageSize();
    const noRowsTemplateLabel = this.userBundle['search.search.noresult'] ? this.userBundle['search.search.noresult'] : 'No Results, please try to “Load More” or “Load All”';
    this.noRowsTemplate = `<span>${noRowsTemplateLabel}</span>`;
    this.setColumnDefs();
    this.setGridOption();
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.userBundle = {};
        this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
        this.setColumnDefs();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  /**
   * On search tab change reset the pagination values
   */
  onTabChange() {
    this.totalRec = 0;
    this.cursor = null;
    this.lastPage = 0;
    this.gridOptions.api.setRowData([]);
    this.campaignsData = [];
  }

  /**
   * On page size drop down changes
   */
  onPageSizeChanged() {
    if (this.gridOptions.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.pageSize));
      this.currentPage = 1;
      this.gridOptions.api.paginationGoToPage(this.currentPage - 1);
      this.lastPage = Math.ceil(this.totalRec / this.pageSize);
    }
  }

  /**
   * Method to export smart search result to CSV file
   */
  onBtnExport() {
    if (this.gridOptions.api) {
      const params: any = {
        fileName: `SmartSearchResult-${new Date().toISOString()}.csv`,
        columnKeys: this.columnDefs.filter(c => c.field && this.isColumnVisible(c.field) && (c.hide === false)).map(c => c.field)
      };

      params.processCellCallback = (cellParams) => {
        if (cellParams.value && moment(cellParams.value, moment.ISO_8601, true).isValid() && moment(new Date(cellParams.value)).isValid()) {
          return this.dateFormatPipe.transform(cellParams.value, LocaleData.displayDateFormat);
        }
        return cellParams.value;
      };

      this.gridOptions.api.exportDataAsCsv(params);
    }
  }

  getRowClass = (params) => {
    if (params.node.data['save'] &&
      (params.node.data['save'].toLowerCase().includes('in progress') || params.node.data['save'].toLowerCase().includes('failed'))) {
      return 'inprogress';
    }
  }

  setGridOption() {
    this.gridOptions = {
      rowHeight: 35,
      floatingFiltersHeight: 40,
      onRowClicked: (event) => {
        if (event && event.event && event.event['ctrlKey']) { // SM-7923
          this.loadCampaignInNewTab(event.data);
        } else {
          this.loadCampaign(event.data);
        }
        SystemFlags.isCloneCampaign = false;
        SystemFlags.isRangeSelectionMandatory = false;
        SystemFlags.splitted = false;
        this.dataShareService.enableGeoMapperTab(true);
        this.dataShareService.enableVisualPlannerTab(true);
      },
      paginationPageSize: this.pageSize,
      pagination: true,
      suppressContextMenu: true,
      suppressPaginationPanel: true,
      onGridReady: (params) => {
        const defaultSortModel = [];
        if (this.uiControl.defaultSortByCampaignReference) {
            defaultSortModel.push(
            {
              colId: 'campaignReference',
              sort: 'desc'
            }
          );
        } else {
          defaultSortModel.push(
            {
              colId: 'createDate',
              sort: 'desc'
            },
            {
              colId: 'campaignReference',
              sort: 'desc'
            }
          );
        }
        
        params.api.setSortModel(defaultSortModel);
        this.findCampaigns();
      },
      onFilterChanged: () => {
        this.totalRec = this.gridOptions.api.paginationGetRowCount();
        this.lastPage = Math.ceil(this.totalRec / this.pageSize);
        this.gridOptions.api.hideOverlay();
        this.setNoRowMessage();
        // we had to do this to over come limitation of ag-grid
        this.changeDetectorRef.detach();
        this.changeDetectorRef.detectChanges();
        this.changeDetectorRef.reattach();
        this.setNoRowMessage();
        if (!this.isLastPageSelected) {
          this.currentPage = 1;
        }
        this.isLastPageSelected = false;
        if (this.typeSelection !== SearchTypeSelction.apiSearches) {
          this.gridOptions.columnApi.setColumnVisible('save', this.isColumnVisible('save'));
        } else {
          this.gridOptions.columnApi.setColumnVisible('save', false);
        }

        this.pageChange();
      },
      onPaginationChanged: () => {
        if (this.gridOptions.api) {
          this.totalRec = this.gridOptions.api.paginationGetRowCount();
        }
      },
      defaultColDef: {
        resizable: true,
        suppressMenu: true,
        filter: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        unSortIcon: true,
        sortable: true,
        suppressMovable: true,
        floatingFilter: true,
        filterParams: { newRowsAction: 'keep'}
      },
      icons: {
        filter: '<i class="fa fa-filter"/>',
        sortAscending: '<i class="fa fa-sort-asc"/>',
        sortDescending: '<i class="fa fa-sort-desc"/>',
        sortUnSort: '<i class="fa fa-sort"/>',
      },
      sortingOrder: ['desc', 'asc'], // SM-3748 - Shivani Patel
      // SM-3579 - Alkesh Shah
      // no need to show grid loader as we are already showing global loader during data load
      overlayLoadingTemplate: '<span></span>',
      scrollbarWidth: 10
    };
  }

  /**
   * get Campaign list to show in grid
   */
  findCampaigns(loadAllCampaigns = false, page?: number) {
    const params = {
      action: 'campaignLookup',
      loadAll: loadAllCampaigns
    };
    let dataKey = '';

    switch (this.typeSelection) {
      case SearchTypeSelction.searches:
        params.action = 'searchLookup';
        dataKey = 'search';
        break;
      case SearchTypeSelction.requestOptions:
        params.action = 'getCampaignBookingRequests';
        dataKey = 'requests';
        break;
      case SearchTypeSelction.campaigns:
        params.action = 'campaignLookup';
        dataKey = 'booking';
        break;
      case SearchTypeSelction.apiSearches:
        params.action = 'apiRequestLookup';
        dataKey = 'apiRequest';
        break;
      default:
        dataKey = 'booking';
        break;
    }

    if (this.gridOptions && this.gridOptions.columnApi) {
      if (this.typeSelection !== SearchTypeSelction.requestOptions) {
        this.gridOptions.columnApi.setColumnVisible('requestBookingDroppingDate', false);
      } else {
        this.gridOptions.columnApi.setColumnVisible('requestBookingDroppingDate', this.isColumnVisible('requestBookingDroppingDate'));
      }
    }
    if (this.cursor) {
      params['cursor'] = this.cursor;
    }

    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Smartsearch,
      SearchTypeSelction[this.typeSelection], 'Search');
    this.smartSearchService.getSavedCampaign(params).subscribe((response: any) => {
      if (response) {
        if (response.status === 'OK') {
          if (loadAllCampaigns) {
            this.campaignsData = []; // reset existing data to avoid duplication as load all will return all data
          }
          this.campaignsData = _.concat(this.campaignsData, [...response.data[dataKey]]) || [];
          this.cursor = response.cursor;
          this.totalRec = this.totalRec ? this.campaignsData.length : response.data[dataKey].length;
          this.lastPage = Math.ceil(this.totalRec / this.pageSize);
        } else {
          this.logHelperService.logError(response.message);
        }
      }
      if (this.gridOptions.api) {
        const model = this.gridOptions.api.getFilterModel();
        this.gridOptions.api.setRowData(this.campaignsData);
        if ((model.endDate && model.endDate.value !== '') ||
        (model.optionDroppingDate && model.optionDroppingDate.value !== '') ||
        (model.startDate && model.startDate.value !== '')) {
          this.totalRec = this.gridOptions.api.paginationGetRowCount();
          this.lastPage = Math.ceil(this.totalRec / this.pageSize);
        } else {
          this.gridOptions.api.setFilterModel(model);
        }
      }
      this.autoSizeAll();
      page ? this.currentPage = page : this.currentPage = 1;
      this.pageChange();
    }, (error: string) => {
      this.logHelperService.logError(error);
    });
  }

  /**
   * Callback function - called on Row click
   * load specific campaign
   * @param data clicked row data
   */
  loadCampaign(data: any): void {
    const params = {
      id: data.bricsCampaignId || data.smartApiRequestId,
      action: this.typeSelection
    };
    this.searchService.emitLoadCampaign({ campaign: data, requestParams: params });
  }

  /**
   * Load campaign in new tab
   * @param data clicked row data
   */
  loadCampaignInNewTab(data: any): void {
    // http://localhost:9797/?market=market:jcd:be&id=5103&action=commercial
    const market = this.appHeaderService.getCookie('market');
    const url = `${location.origin}/?market=${market}&id=${data.bricsCampaignId || data.smartApiRequestId}&action=search`;
    window.open(url, '_blank').focus();
  }

  /**
   * Callback function - called on clone button click
   * load specific campaign to clone it
   * @author Dhaval Patel
   * @memberof SmartSearchComponent
   */
  async cloneCampaign(data: any) {
    this.searchService.emitCloneCampaign(data);
  }

  /**
   * pagination page change call back function
   * @memberof SmartSearchComponent
   */
  pageChange() {
    if (this.gridOptions.api) {
      this.gridOptions.api.paginationGoToPage(this.currentPage - 1);
    }
    this.autoSizeAll();
  }

  setNoRowMessage() {
    if (this.totalRec === 0 && this.cursor && this.uiControl.applyLimitOnSearch) {
      this.noRowsTemplate = this.userBundle['search.search.noresult'] ? `<span>${this.userBundle['search.search.noresult']}</span>` : 'No Results, please try to “Load More” or “Load All”';
      this.gridOptions.api.showNoRowsOverlay();
    } else {
      this.noRowsTemplate = this.userBundle['search.noRows'] ? `<span>${this.userBundle['search.noRows']}</span>` : 'No Rows To Show';
    }
  }
  autoSizeAll(skipHeader = false) {
    const allColumnIds = [];
    if (this.gridOptions.columnApi) {
      this.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }

  /**
   * Go to specific page form pagination
   * @param page - page number clicked from the pagination control
   */
  public onGoTo(page: number): void {
    this.currentPage = page;
    this.isLastPageSelected = false;
    if (page === this.lastPage && this.cursor && this.uiControl.applyLimitOnSearch  && (this.totalRec >= this.campaignsData.length)) {
      this.isLastPageSelected = true;
      this.findCampaigns(false, page);
    } else {
      this.pageChange();
    }
  }

  /**
   * Go to next page from pagination
   * @param page current page number
   */
  public onNext(page: number): void {
    this.currentPage = page + 1;
    this.pageChange();
  }

  /**
   * Go to previous page from current page
   * @param page current page number
   */
  public onPrevious(page: number): void {
    this.currentPage = page - 1;
    this.pageChange();
  }

  /**
   * Go to first page
   */
  public onFirstPage(): void {
    this.currentPage = 1;
    this.pageChange();
  }

  /**
   * Go to Last page
   */
  public onLastPage(): void {
    this.currentPage = this.lastPage;
    this.isLastPageSelected = true;
    if (this.cursor && this.uiControl.applyLimitOnSearch && (this.totalRec >= this.campaignsData.length)) {
      this.findCampaigns(false, this.lastPage);
    }
    this.pageChange();
  }

  /**
   * load all records instead of paginated data
   */
  loadAll() {
    if (confirm(this.userBundle['search.search.loadall'] || 'This might take more time to load data, are you sure to continue loading all data?')) {
      this.cursor = null;
      this.findCampaigns(true);
    }
  }

  loadMore() {
    this.findCampaigns(false);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * This method is used to get page size and to manage page size options.
   */
  setPerPageSize() {
    this.pageSize = this.uiControl['defaultSmartSearchResultsPerPage'] ? this.uiControl['defaultSmartSearchResultsPerPage'] : 10;
    const index = this.pageSizeOptions.findIndex(pso => pso === this.pageSize);
    if (index === -1) {
      this.pageSizeOptions.push(this.pageSize);
      this.pageSizeOptions.sort((a, b) => { return a-b });
    }
  }
}
